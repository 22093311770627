.licenseFilter{
  position: relative;
  width: 100%;
  height: 20px;
  color: #6d6d6d;
  text-align: left;
  box-sizing: border-box;
  padding: 5px;
  line-height: 20px;
}

.addUserBtn{
  margin-left: 25px;
}

.licenseFilterExpireDate {
  position: relative;
  float: left;
  width: 16%;
  bottom: 0px;
  cursor: pointer;
}

.licenseFilterStatus {
  position: relative;
  float: left;
  width: 16%;
  bottom: 0px;
  cursor: pointer;
}

.licenseFilterId {
  position: relative;
  float: left;
  width: 16%;
  bottom: 0px;
  cursor: pointer;
}

.licenseFilterDaysRemaining {
  position: relative;
  float: left;
  width: 16%;
  bottom: 0px;
  cursor: pointer;
  text-align: left;
}

.licenseFilterUpdate {
  position: relative;
  float: left;
  width: 20%;
  bottom: 0px;
  cursor: pointer;
  text-align: left;
}

.userFilter img {
  position: relative;
  height: 20px;
  line-height: 20px;
  width: auto;
  display: inline-block;
}