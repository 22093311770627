/*.newsPreviewWrapper{
  position: relative;
  float: left;
  width: 31.3%;
  height: 350px;
  background-color: #EBEBEB;
  margin-left: 1%;
  margin-right: 1%;
  text-align: center;
  box-sizing: border-box;
  padding: 5px;
  cursor: pointer;
  margin-bottom: 20px;
}*/

.bimPreviewWrapper {
  position: relative;
  float: left;
  width: 31.3%;
  height: 350px;
  text-align: left;
  margin-left: 1%;
  margin-right: 1%;
  text-align: center;
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 5px 15px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px;
  margin-bottom: 8px;
  color: rgb(76, 83, 93);
  border-radius: 4px;
  box-sizing: border-box;
  margin-top: 20px;
  cursor: pointer;
}

.bimPreviewTitle{
  position: relative;
  float: left;
  width: 100%;
  height: 20%;
  font-size: 18px;
  font-weight: bold;
  /* border-bottom-style: solid; */
  /* border-bottom-color: #d6d6d6; */
  /* border-bottom-width: 1px; */
  /* min-height: 75px; */
  box-sizing: border-box;
  padding: 15px;
  text-decoration: none;
  color: #000;
  line-height: 45px;
}

.newsPreviewTitle a{
  text-decoration: none;
  color: #000;
}

.bimPreviewImage {
  position: relative;
  /* display: block; */
  float: left;
  width: 100%;
  /* max-height: 220px; */
  height: 80%;
  /* margin-top: 2%; */
  text-align: center;
  border-radius: 4px;
  background-color: #eaeaea;
}

.bimPreviewImage img{
  position: relative;
  /*display: block;*/
  /* float: left; */
  height: 100%;
  width: auto;
  max-width: 100%;
}

@media screen and (max-width : 1050px) {
  .newsPreviewWrapper{
    width: 100%;
    margin-left: 0px;
  }

  .newsPreviewImage{
    width: 100%;
    max-width: 100%;
    margin-left: 0px;
  }
}