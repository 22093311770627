.pageDescription{
  position: relative;
  float: left;
  width: 80%;
  height: auto;
  margin-left: 10%;
  font-size: 15px;
  text-align: left;
  margin-top: 15px;
  margin-bottom: 30px;
}

.pageDescriptionDemo{
  position: relative;
  float: left;
  width: 80%;
  height: auto;
  margin-left: 10%;
  font-size: 15px;
  text-align: left;
  margin-top: 15px;
  margin-bottom: 30px;
}

.howitworksExplanation{
  position: relative;
  width: 100%;
  height: auto;
}

.howitworksIndustryWrapper{
  position: relative;
  float: left;
  width: 100%;
  height: auto;
  margin-bottom: 50px;
}

.industryWrapper{
  position: relative;
  float: left;
  width: 31.3%;
  height: 250px;
  text-align: left;
  margin-left: 1%;
  margin-right: 1%;
  text-align: center;
  background-color: #fff;
  box-shadow: 0 5px 15px 0 rgba(0,0,0,.08), 0 1px 3px 0 rgba(0,0,0,.12);
  margin-bottom: 8px;
  color: #4c535d;
  border-radius: 4px;
  box-sizing: border-box;
  padding: 10px;
  cursor: pointer;
}

.industryWrapperTitle{
  position: relative;
  width: 100%;
  height: 50px;
  text-align: left;
  font-size: 28px;
  font-weight: bold;
}

.industryWrapperImg{
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 100px;
  height: auto;
}

.industryWrapperImg img{
  width: 100%;
  height: auto;
}

.industryDetailWrapper{
  position: relative;
  float: left;
  width: 100%;
  height: auto;
  background-color: rgb(255, 255, 255);
  box-sizing: border-box;
  padding: 20px;
  font-family:Lomino, LominoSubset, "open-sans", sans-serif; 
  margin-bottom: 50px;
}

.industryDetailTitle{
  position: relative;
  float: left;
  width: 100%;
  text-align: center;
  font-size: 60px;
  font-weight: 600;
}

.industryDetailDescription{
  position: relative;
  float: left;
  width: 100%;
  text-align: center;
  font-size: 16px;
  font-weight: 300;
  margin-bottom: 30px;
}

.industryDetailImage{
  position: relative;
  float: left;
  width: 100%;
  height: auto;
}

.industryDetailImage img{
  position: relative;
  float: left;
  width: 100%;
  height: auto;
}

.industryWrapperNextImg{
  position: absolute;
  bottom: 0px;
  right: 0px;
  width: 100px;
  height: auto;
}

.industryWrapperNextImg img{
  width: 100%;
  height: auto;
}

.howItWorksVideo{
  width: 100%;
  height: auto;
  position: relative;
  overflow: hidden;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 5px 15px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px;
  margin-bottom: 8px;
  color: rgb(76, 83, 93);
  border-radius: 4px;
  box-sizing: border-box;
  padding: 15px;
  background-color: white;
}

.featureRow{
  position: relative;
  float: left;
  width: 100%;
  height: auto;
  font-size: 15px;
  margin-bottom: 35px;
}

.keyFeature{
  position: relative;
  float: left;
  width: 33%;
  height: auto;
  text-align: left;
}

.featureDesc{
  position: relative;
  float: left;
  width: 66%;
  height: 100%;
}

.featureTitle{
  position: relative;
  float: left;
  width: 100%;
  height: auto;
  font-size: 18px;
  margin-bottom: 15px;
}

@media screen and (max-width : 1050px) {
  .keyFeature{
    width: 100%;
    text-align: center;
    margin-bottom: 10px;
  }

  .pageDescription{
    text-align: center;
    width: 90%;
    margin-left: 5%;
  }

  .pageDescriptionDemo{
    text-align: left;
    width: 90%;
    margin-left: 5%;
  }

  .featureRow{
    text-align: center;
    margin-bottom: 0px;
  }

  .featureDesc{
    width: 100%;
  }

  .industryWrapper{
    width: 100%;
    margin-left: 0px;
    margin-right: 0px;
    height: 150px;
  }

  .industryWrapperTitle{
    font-size: 20px;
  }

  .industryDetailTitle{
    font-size: 35px;
  }
}