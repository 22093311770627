a{
  text-decoration: none;
}

.allRequestsWrapper{
	position: relative;
	float: left;
	height: auto;
	width: 100%;
	margin-bottom: 25px;
}