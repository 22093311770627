.devicePreviewWrapper {
    position: relative;
    width: 14%;
    height: 250px;
    margin-left: 1%;
    margin-right: 1%;
    text-align: center;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.08) 0px 5px 15px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px;
    margin-bottom: 8px;
    color: rgb(76, 83, 93);
    border-radius: 4px;
    box-sizing: border-box;
    padding: 15px;
    float: left;
}

.devicePreviewModel{
	font-size: 11px;
	position: relative;
	width: 100%;
	height: auto;
	display: inline-block;
}

.devicePreviewImage{
	position: relative;
    display: inline-block;
    /* float: left; */
    max-width: 80%;
    width: auto;
    max-height: 220px;
    height: auto;
    /* margin-left: 10%; */
    /* margin-top: 15px; */
    text-align: center;
    vertical-align: middle;
}

.devicePreviewTitle{
	position: relative;
  	width: 100%;
  	height: 50%;
  	font-size: 18px;
  	font-weight: bold;
  	border-bottom-style: solid;
  	border-bottom-color: #d6d6d6;
  	border-bottom-width: 1px;
  	box-sizing: border-box;
  	padding: 15px;
}

.devicePreviewImage img{
	max-width: 100%;
  	width: auto;
  	height: auto;
  	max-height: 220px;
  	display: inline-block;
  	vertical-align: middle;
  	margin-top: 20px;
}

@media screen and (max-width : 900px) {
  .devicePreviewWrapper{
    width: 90%;
    margin-left: 5%;
  }

  .newsPreviewImage{
    width: 100%;
    max-width: 100%;
    margin-left: 0px;
  }
}