.requestWrapper{
  position: relative;
  float: left;
  width: 100%;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 5px 15px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px;
  border-radius: 4px;
  height: 60px;
  cursor: pointer;
  margin-top: 10px;
  box-sizing: border-box;
  padding: 5px;
  text-align: left;
  line-height: 50px;
  text-decoration: none;
  color: #000;
  font-size: 18px;
}

.requestStatus {
  position: relative;
  float: left;
  width: 10%;
  bottom: 0px;
}

.requestStatus img {
  position: relative;
  float: left;
  width: 28px;
  height: 31px;
  margin-top: 10px;
  margin-left: 15px;
}

.requestId{
  position: relative;
  float: left;
  width: 15%;
  height: 60px;
  overflow: hidden;
  cursor: pointer;
  box-sizing: border-box;
  padding-right: 10px;
}

.requestName {
  position: relative;
  float: left;
  width: 20%;
  height: 60px;
  overflow: hidden;
  cursor: pointer;
  box-sizing: border-box;
  padding-right: 10px;
}

.requestLocation {
  position: relative;
  float: left;
  width: 10%;
  cursor: pointer;
}

.requestUtilityType{
  position: relative;
  float: left;
  width: 13%;
  cursor: pointer;
}

.requestStartDate {
  position: relative;
  float: left;
  width: 11%;
  cursor: pointer;
}

.requestEndDate {
  position: relative;
  float: left;
  width: 11%;
  cursor: pointer;
}

.requestStatus {
  position: relative;
  float: left;
  width: 10%;
  text-align: right;
  cursor: pointer;
}

a{
  text-decoration: none;
}