.editSessionWrapper{
  position: relative;
  float: left;
  width: 100%;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 5px 15px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px;
  border-radius: 4px;
  height: 60px;
  cursor: pointer;
  margin-top: 10px;
  box-sizing: border-box;
  padding: 5px;
  text-align: left;
  line-height: 50px;
  text-decoration: none;
  color: #000;
  font-size: 18px;
}

.editSessionId {
  position: relative;
  float: left;
  width: 25%;
  height: auto;
  overflow: ellipsis;
  text-align: left;
}

.editSessionName{
  position: relative;
  float: left;
  width: 25%;
  height: auto;
  overflow: ellipsis;
  text-align: left;
}

.editSessionCreator{
  position: relative;
  float: left;
  width: 25%;
  height: auto;
  overflow: ellipsis;
  text-align: left;
}

.editSessionDate {
  position: relative;
  float: left;
  width: 25%;
  height: auto;
  overflow: ellipsis;
  text-align: right;
}