.adminModuleWrapper{
	position: relative;
	float: left;
	width: 22%;
	margin-left: 1.5%;
	margin-right: 1.5%;
	margin-top: 20px;
	height: 150px;
	box-shadow: rgba(0, 0, 0, 0.08) 0px 5px 15px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px;
	margin-bottom: 8px;
	color: rgb(76, 83, 93);
	border-radius: 4px;
	box-sizing: border-box;
	padding: 15px;
	background-color: white;
	cursor: pointer;
}

.adminModuleWrapperSelected{
	position: relative;
	float: left;
	width: 22%;
	margin-left: 1.5%;
	margin-right: 1.5%;
	margin-top: 20px;
	height: 150px;
	background-color: #007bff;
	box-shadow: rgba(0, 0, 0, 0.08) 0px 5px 15px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px;
	margin-bottom: 8px;
	color: rgb(76, 83, 93);
	border-radius: 4px;
	box-sizing: border-box;
	padding: 15px;
	cursor: pointer;
}

.adminModuleWrapper{
	text-decoration: none;
	color: #000;
}

.adminModuleLarge{
	position: relative;
	float: left;
	width: 100%;
	text-align: center;
	height: 80%;
	/*line-height: 200px;*/
	font-size: 80px;
	font-weight: bold;
}

.adminModuleLarge img{
	position: relative;
	max-width: 90%;
	margin: auto;
	height: 100%;
}

.adminModuleFooter{
	position: relative;
	float: left;
	height: 20%;
	width: 100%;
	text-align: center;
	font-size: 20px;
}