.kmluploadwrapper{
    text-align: center;
    /* background-color: #4c96d3; */
    padding: 30px 6px;
    margin: 15px 0px 15px 0px;
    font-size: 14px;
    line-height: 1.42857143;
    color: #1A4882;
    background-image: none;
    background-color: rgba(238, 238, 238, 0.80);
    /* background-color: #d9edf7; */
    border: 2px dotted rgb(198,40,40,0.99);
    border-radius: 6px;
    margin-top: 10px;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.3);
}

.modelWrapper{
    position: relative;
    float: left;
    margin-top: 10px;
    width: 100%;
    height: 500px;
}

.submitProjectInactiveBtn {
    height: 50px;
    width: 300px;
    float: left;
    border: none;
    box-sizing: border-box;
    padding: 5px;
    background-color: #eb6969;
    display: inline-block;
    color: #ffffff;
    margin: 0px;
    font-size: 16px;
    margin-top: 20px;
}

.submitNoticeWrapper{
    position: relative;
    float: left;
    width: 100%;
    height: auto;
}

/*.modelWrapper canvas{
    width: 100% !important;
    height: 500px !important;
}*/

/*.modelWrapper div{
    position: relative;
    padding-left: 0;
    padding-right: 0;
    margin-left: auto;
    margin-right: auto;
}*/