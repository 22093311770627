.map{
  width: 100% !important;
  height: 600px;
  top: 0px !important;
  position: relative !important;
  overflow: hidden;
  border-style: solid;
  border-width: 1px;
  border-color: #cccccc;
  box-sizing: border-box;
}

.editSessionMapWrapper{
	width: 100% !important;
  height: auto;
  position: relative;
  float: left;
  box-sizing: border-box;
  margin-bottom: 25px;
}

.attributeTitle{
  position: relative;
  float:left;
  width: 100%;
  height: auto;
  background-color: #fff;
}