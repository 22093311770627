.catalogInputField{
  height: 30px;
  font-size: 15px;
  line-height: 30px;
  display: none;
  width: 70%;
}

.featureServiceColors{
	position: relative;
	float: left;
	width: 100%;
	height: auto;
	line-height: 50px;
}

.featureServiceColor{
	position: relative;
	float: left;
	width: 100%;
	height: auto;
	margin-bottom: 5px;
	box-sizing: border-box;
	padding: 10px;
}

.featureServiceColorImgWrapper{
	position: relative;
	float: left;
	width: 50px;
	height: 50px;
	background-color: #000000;
	margin-right: 20px;
}

.colorPickerFullWrapper{
	position: fixed;
    width: 100%;
    height: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1000;
    background: rgb(18 18 18 / 60%) 50% 50% no-repeat;
}

.colorPickerWrapper{
	position: fixed;
	top: 50%;
	left: 50%;
	/* bring your own prefixes */
	transform: translate(-50%, -50%);
	z-index: 1000;
}

.conditionDelete{
	width: auto;
	float: right;
}

.fullWidth{
	float: left;
	width: 100%;
}