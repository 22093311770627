.teamWrapper{
  position: relative;
  float: left;
  width: 100%;
  height: auto;
  margin-bottom: 20px;
}

.teamMemberOdd{
  position: relative;
  float: left;
  width: 100%;
  height: auto;
  background-color: #ebebeb;
  box-sizing: border-box;
  padding: 10px;
  margin-bottom: 10px;
}

.teamMemberImage{
  position: relative;
  float: left;
  width: 20%;
  height: auto;
  box-sizing: border-box;
  padding: 5px;
}

.teamMemberImage img{
  position: relative;
  float: left;
  width: 100%;
  height: auto;
}

.teamMemberDesc{
  position: relative;
  float: left;
  width: 70%;
  height: auto;
  box-sizing: border-box;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 15px;
}

@media screen and (max-width : 1050px) {
  .teamMemberImage{
    width: 100%;
    height: auto;
  }

  .teamWrapper{
    text-align: center;
  }

  .teamMemberDesc{
    width: 100%;
  }

  .pageDescription ul{
    text-align: left;
  }
}