.pageTitle{
	position: relative;
    float: left;
    width: 80%;
    margin-left: 10%;
    color: #c62828;
    font-size: 35px;
    text-align: left;
    margin-top: 15px;
    border-bottom-style: solid;
    border-color: rgb(224, 228, 234);
    border-width: 2px;
}

.loginFormWrapper{
    position: relative;
    float: left;
    width: 100%;
    height: auto;
    margin-top: 25px;
    text-align: center;
}

.loginForm{
	position: relative;
	float: left;
	height: auto;
	width: 100%;
}

.loginAuth0{
    position: relative;
    float: left;
    height: auto;
    width: 100%;
    text-align: middle;
}

.loginAuth0 button{
    background-color: #c62828;
    border: none;
    color: #ffffff;
    height: 50px;
    position: relative;
    width: 50%;
    margin-bottom: 20px;
    font-size: 20px;
    cursor: pointer;
}

.loginAuth0 input{
    position: relative;
    width: 50%;
    height: 30px;
    margin-bottom: 20px;
    font-size: 20px;
}

.loginForm input{
	position: relative;
    width: 50%;
    height: 30px;
    margin-bottom: 20px;
    font-size: 20px;
}

.loginForm button{
	background-color: #c62828;
    border: none;
    color: #ffffff;
    height: 50px;
    position: relative;
    width: 50%;
    margin-bottom: 20px;
    font-size: 20px;
    cursor: pointer;
}

/*.createAccount button{
  background-color: #c62828;
  border: none;
  color: #ffffff;
  height: 50px;
  position: relative;
  max-width: 400px;
  width: 100%;
  margin-bottom: 20px;
  font-size: 20px;
  cursor: pointer;
}

.createAccount{
  text-align: left;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 5px 15px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px;
  margin-bottom: 8px;
  width: 100%;
  min-width: 100%;
  color: rgb(76, 83, 93);
  border-radius: 4px;
  box-sizing: border-box;
  padding: 15px;
}*/

@media screen and (max-width : 750px) {
  .loginAuth0 input{
    width: 100%;
  }

  .loginAuth0 button{
    width: 100%;
  }
}