body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@import url('https://fonts.googleapis.com/css?family=Open+Sans:400italic,600italic,700italic,400,600,700');

.mainWrapper{
  position: relative;
  margin: 0 auto;
  text-align: center;
  width: 100%;
  min-height: 100vh;
  height: auto;
  overflow: hidden;
  z-index: 1;
  /*background-color: #EBEBEB;*/
  background-color: rgb(240, 242, 245);
  left: auto;
  top: auto;
  font-family: 'Open Sans';
}

.mainWindow{
	position: relative;
  margin-left: 60px;
  margin-top: 60px;
  padding: 20px;
  box-sizing: border-box;
}

.mainWindow a{
  text-decoration: none;
}

.wrapper{
  position: relative;
  margin: 0 auto;
  text-align: center;
  width: 100%;
  min-height: 100vh;
  height: auto;
  overflow: hidden;
  z-index: 1;
  /*background-color: #FFFFFF;*/
  background-color: rgb(240, 242, 245);
  left: auto;
  top: auto;
  font-family: 'Open Sans';
}
