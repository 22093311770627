.pageHeader{
  position: relative;
  width: 100%;
  height: 60px;
  border-bottom-style: solid;
  border-width: 2px;
  border-color: rgb(224, 228, 234);
  font-size: 20px;
  line-height: 60px;
  text-align: left;
  float: left;
}

.profileBody{
  position: relative;
  float: left;
  margin-top: 10px;
  width: 100%;
  height: auto;
  text-align: left;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 5px 15px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px;
  border-radius: 4px;
  box-sizing: border-box;
  padding: 15px;
  margin-bottom: 25px;
}

input.companyInput {
  height: 30px;
  font-size: 15px;
  line-height: 30px;
  display: inline-block;
  max-width: 350px;
  width: 100%;
  padding-left:5px;
}