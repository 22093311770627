.addedFeaturesWrapper{
  position: relative;
  float: left;
  width: 100%;
  height: auto;
  margin-top: 30px;
  color: #c62828;
  text-align: left;
  font-size: 22px;
  margin-bottom: 20px;
}

.editSessionHeader{
  position: relative;
  width: 100%;
  height: 60px;
  border-bottom-style: solid;
  border-width: 2px;
  border-color: #acacac;
  font-size: 24px;
  margin-bottom: 10px;
}

.editSessionTitle{
  position: relative;
  width: 100%;
  height: 60px;
  border-bottom-style: solid;
  border-width: 2px;
  border-color: rgb(224, 228, 234);
  font-size: 20px;
  line-height: 60px;
  text-align: left;
}

.editSessionClose{
  position: relative;
  width: auto;
  height: 60px;
  font-size: 20px;
  line-height: 60px;
  float: right;
  cursor: pointer;
  z-index: 10;
  text-decoration: none;
  color: #000;
}

.editSessionClose a{
  text-decoration: none;
  color: #000;
}

.editSessionResponses{
  position: relative;
  float: left;
  width: 100%;
  height: auto;
  text-align: left;
  margin-bottom: 10px;
}

.editSessionResponses button{
  position: relative;
  float: left;
  margin-right: 10px;
  height: 50px;
}

.noAssets {
  position: relative;
  float: left;
  width: 100%;
  /* margin-left: 10%; */
  height: auto;
  color: #000;
  box-sizing: border-box;
  padding: 15px;
  margin-top: 20px;
  box-shadow: 0 0 10px rgba(0,0,0,0.6);
  -moz-box-shadow: 0 0 10px rgba(0,0,0,0.6);
  -webkit-box-shadow: 0 0 10px rgba(0,0,0,0.6);
  -o-box-shadow: 0 0 10px rgba(0,0,0,0.6);
  border-radius: 15px;
  background-color: #f9f8f8;
  border-color: #868686;
}