.solutionWrapper {
    position: relative;
    float: left;
    width: 45%;
    height: 350px;
    text-align: left;
    margin-left: 2%;
    margin-right: 2%;
    text-align: center;
    background-color: #fff;
    box-shadow: 0 5px 15px 0 rgba(0,0,0,.08), 0 1px 3px 0 rgba(0,0,0,.12);
    margin-top: 25px;
    color: #4c535d;
    border-radius: 4px;
    box-sizing: border-box;
    padding: 10px;
    cursor: pointer;
}

.solutionFullWrapper {
    position: relative;
    float: left;
    width: 94%;
    height: 350px;
    text-align: left;
    margin-left: 2%;
    margin-right: 2%;
    text-align: center;
    background-color: #fff;
    box-shadow: 0 5px 15px 0 rgba(0,0,0,.08), 0 1px 3px 0 rgba(0,0,0,.12);
    margin-top: 25px;
    color: #4c535d;
    border-radius: 4px;
    box-sizing: border-box;
    padding: 10px;
    cursor: pointer;
}

.solutionFullWrapperImage {
    position: relative;
    max-width: 250px;
    max-height: 200px;
    /* height: auto; */
    width: auto;
    float: left;
    box-sizing: border-box;
    padding: 10px;
}

.solutionFullWrapperImage img{
    position: relative;
    width: 100%;
    height: 100%;
}

.solutionWrapperImage {
    position: relative;
    width: 50%;
    max-width: 250px;
    height: auto;
    float: left;
    box-sizing: border-box;
    padding: 10px;
    max-height: 250px;
}

.solutionWrapperImage img{
    position: relative;
    width: 100%;
    height: 100%;
}

.solutionWrapperTitle {
    position: relative;
    width: 100%;
    height: 50px;
    text-align: left;
    font-size: 18px;
    font-weight: bold;
}

.solutionTextWrapper{
    position: relative;
    float: left;
    width: 50%;
    height: 80%;
}

.solutionDescription{
    position: relative;
    float: left;
    width: 100%;
    height: auto;
    font-size: 12px;
    text-align: left;
}

.solutionPageExplanation {
    position: relative;
    float: left;
    width: 80%;
    margin-left: 10%;
    height: auto;
    margin-top: 10px;
    margin-bottom: 30px;
    font-size: 28px;
    text-align: center;
    font-family: McKinsey Sans,Helvetica Neue,Calibri,Corbel,Helvetica,Roboto,Droid,sans-serif;
}

.downloadWrapper{
    position: relative;
    float: left;
    width: 100%;
    height: 20%;
    box-sizing: border-box;
    text-align: center;
}

.downloadWrapper img{
    position: relative;
    float: left;
    max-width: 50%;
    height: auto;
    max-height: 65px;
    display: block;
}

@media screen and (max-width : 1050px) {
    .solutionFullWrapper {
        position: relative;
        float: left;
        width: 96%;
        height: auto;
        text-align: left;
        margin-left: 2%;
        margin-right: 2%;
        text-align: center;
        background-color: #fff;
        box-shadow: 0 5px 15px 0 rgba(0,0,0,.08), 0 1px 3px 0 rgba(0,0,0,.12);
        margin-top: 25px;
        color: #4c535d;
        border-radius: 4px;
        box-sizing: border-box;
        padding: 10px;
        cursor: pointer;
    }

    .solutionWrapper {
        position: relative;
        float: left;
        width: 96%;
        height: auto;
        text-align: left;
        margin-left: 2%;
        margin-right: 2%;
        text-align: center;
        background-color: #fff;
        box-shadow: 0 5px 15px 0 rgba(0,0,0,.08), 0 1px 3px 0 rgba(0,0,0,.12);
        margin-top: 25px;
        color: #4c535d;
        border-radius: 4px;
        box-sizing: border-box;
        padding: 10px;
        cursor: pointer;
    }

    .solutionFullWrapperImage {
        position: relative;
        width: 100%;
        max-width: none;
        max-height: none;
        height: auto;
        float: left;
        box-sizing: border-box;
        padding: 10px;
    }

    .solutionWrapperImage {
        position: relative;
        width: 100%;
        max-width: none;
        max-height: none;
        height: auto;
        float: left;
        box-sizing: border-box;
        padding: 10px;
    }

    .solutionTextWrapper{
        position: relative;
        float: left;
        width: 100%;
        height: auto;
    }

    .solutionDescription{
        position: relative;
        float: left;
        width: 100%;
        height: auto;
        font-size: 12px;
        text-align: left;
    }
}