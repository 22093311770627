.StripeElement {
  margin: 15px auto;
  padding: 10px 12px;
  color: #32325d;
  background-color: whitesmoke;
  border: 1px solid transparent;
  border-radius: 4px;
  width: 100%;
  position: relative;
  float: left;
}

.stripeForm{
  position: relative;
  float: left;
  width: 100%;
  height: auto;
}

.stripeForm label{
  width: 100%;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}

.product {
  width: 100%;
  max-width: 450px;
  margin: auto;
  box-shadow: 0px 15px 30px rgba(0, 0, 0, 0.4);
  border-radius: 10px;
  overflow: hidden;
}

.btn-pay {
  display: block;
  width: 100%;
  border: none;
  background-color: #007bff;
  color: #fff;
  padding: 10px;
  font-size: 18px;
  cursor: pointer;
}

.product-info {
  padding: 0 16px;
}

h3.product-title {
  font-size: 24px;
  margin-bottom: 15px;
}

h4.product-price {
  font-size: 24px;
  margin: 0;
  margin-bottom: 30px;
  color: #777;
  font-weight: 500;
}

.product-description{
  position: relative;
  width: 100%;
  height: auto;
  float: left;
  margin-bottom: 25px;
}

.product-total{
  position: relative;
  width: 100%;
  height: auto;
  float: left;
  margin-bottom: 25px;
  border-top: solid;
  border-top-width: 3px;
  border-color: #ededed;
  text-align: right;
  font-weight: bold;
  font-size: 20px;
}

.subscriptionWrapper{
  text-align: left;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 5px 15px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px;
  margin-bottom: 8px;
  margin-left: 2%;
  margin-right: 2%;
  width: 29%;
  color: rgb(76, 83, 93);
  border-radius: 4px;
  box-sizing: border-box;
  padding: 15px;
  float:left;
  height: auto;
}

.subscriptionWrapperLarge{
  text-align: left;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 5px 15px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px;
  margin-bottom: 8px;
  margin-left: 2%;
  margin-right: 2%;
  width: 29%;
  color: rgb(76, 83, 93);
  border-radius: 4px;
  box-sizing: border-box;
  padding: 15px;
  float:left;
  height: auto;
}

.product-bottom{
  position: relative;
  float: left;
  width: 100%;
  height: auto;
}

.product-bottom-buffer{
  position: relative;
  float: left;
  width: 100%;
  height: 230px;
}

.product-info-wrapper{
  position: relative;
  width: 100%;
  height: auto;
}

.subscriptionWrapper img{
  position: relative;
  float: left;
  width: 100%;
  height: auto;
  margin-bottom: 25px;
}

.subscriptionWrapperLarge img{
  position: relative;
  float: left;
  width: 100%;
  height: auto;
  margin-bottom: 25px;
}

@keyframes fade {
  from {
    opacity: 0;
    transform: scale3D(0.95, 0.95, 0.95);
  }
  to {
    opacity: 1;
    transform: scale3D(1, 1, 1);
  }
}

.Form {
  animation: fade 200ms ease-out;
}

.FormGroup {
  margin: 0 15px 20px;
  padding: 0;
  border-style: none;
  background-color: #f7f7f7;
  will-change: opacity, transform;
  /*box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08),
    inset 0 1px 0 #829fff;*/
  border-radius: 4px;
  color: #000000 !important;
}

.FormRow {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  margin-left: 15px;
  /*border-top: 1px solid #819efc;*/
}

.FormRow:first-child {
  border-top: none;
}

.FormRow label{
  margin-bottom: 0px !important;
  width: 20px;
}

.FormRowLabel {
  width: 15%;
  min-width: 70px;
  padding: 11px 0;
  color: #000000;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@keyframes void-animation-out {
  0%,
  to {
    opacity: 1;
  }
}
.FormRowInput:-webkit-autofill {
  -webkit-text-fill-color: #fce883;
  /* Hack to hide the default webkit autofill */
  transition: background-color 100000000s;
  animation: 1ms void-animation-out;
}

.FormRowInput {
  font-size: 16px;
  width: 100%;
  padding: 11px 15px 11px 5px;
  color: #000000;
  background-color: transparent;
  animation: 1ms void-animation-out;
  border-color: #0000004a;
  border-top-color: rgba(0, 0, 0, 0.29);
  border-right-color: rgba(0, 0, 0, 0.29);
  border-bottom-color: rgba(0, 0, 0, 0.29);
  border-left-color: rgba(0, 0, 0, 0.29);
  border-width: 1px;
}

::placeholder{
  color: #000000;
}

.FormRowInput::placeholder {
  color: #87bbfd;
}

@media screen and (max-width : 1050px) {
  .subscriptionWrapper{
    margin-left: 0%;
    margin-right: 0%;
    width: 100%;
  }

  .subscriptionWrapperLarge{
    margin-left: 0%;
    margin-right: 0%;
    width: 100%;
  }

  .subscriptionGroup{
    margin-bottom: 0px;
  }
}