/* Style the form - display items horizontally */
.form-inline {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    width: 33%
  }
  
  /* Add some margins for each label */
  .form-inline label {
    margin: 5px 10px 5px 0;
  }
  
  /* Style the input fields */
  .form-inline input {
    width: 100%;
    padding: 12px 20px;
    margin: 8px 0;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 0;
    box-sizing: border-box;
  }
  .form-inline input[type=submit] {
    width: 100%;
    background-color: #4CAF50;
    color: white;
    padding: 14px 20px;
    margin: 8px 0;
    border: none;
    border-radius: 0;
    cursor: pointer;
  }
  
  /* Style the submit button */
  .form-inline button {
    padding: 10px 20px;
    background-color: #c62828;
    border: none;
    color: white;
  }
  
  .form-inline button:hover {
    background-color: #c62828;
  }
  
  /* Add responsiveness - display the form controls vertically instead of horizontally on screens that are less than 800px wide */
  @media (max-width: 800px) {
    .form-inline input {
      margin: 10px 0;
    }
  
    .form-inline {
      flex-direction: column;
      align-items: stretch;
    }
  }

  /* Style the submit button */
  .cancel-btn {
    width: 100%;
    background-color:#c62828;
    color: white;
    padding: 14px 20px;
    margin: 8px 0;
    border: none;
    border-radius: 0;
    cursor: pointer;
  }