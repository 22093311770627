/*@import url('https://js.arcgis.com/3.28/esri/css/esri.css');
@import url('https://js.arcgis.com/3.28/dijit/themes/nihilo/nihilo.css');*/

.createProjectWrapper {
  position: relative;
  box-sizing: border-box;
  text-align: left !important;
  height: auto;
}

.createProjectHeader {
  position: relative;
  width: 100%;
  height: 60px;
  border-bottom-style: solid;
  border-width: 2px;
  border-color: rgb(224, 228, 234) !important;
  font-size: 24px;
}

.projectButtonSelected {
  position: relative;
  float: left;
  width: auto;
  height: 60px;
  line-height: 60px;
  margin-right: 50px;
  border-bottom-style: solid;
  border-width: 2px;
  border-color: #C62828;
  cursor: pointer;
}

.createProjectInfoWrapper {
  position: relative;
  float: left;
  width: 100%;
  height: auto;
  margin-top: 10px;
  margin-bottom: 50px;
}

input.projectInputField {
  height: 30px;
  font-size: 15px;
  line-height: 30px;
  display: inline-block;
  width: 70%;
  padding-left: 5px;
}

textarea.projectInputArea {
  min-height: 150px;
  font-size: 15px;
  line-height: 30px;
  display: inline-block;
  max-width: 90%;
  min-width: 90%;
  font-family: Arial;
  padding-left: 5px;
}

input.projectInputDate {
  height: 30px;
  font-size: 15px;
  line-height: 30px;
  display: inline-block;
  width: 120px;
  padding-left: 5px;
}

select.projectSelect {
  height: 30px;
  font-size: 15px;
  line-height: 30px;
  display: inline-block;
  width: auto;
  max-width: 100%;
  padding: 0px;
}

.mapHeader {
  height: 80px;
  overflow: auto;
  padding: 0.5em;
  position: relative !important;
  border-left-style: solid;
  border-right-style: solid;
  border-top-style: solid;
  border-bottom-style: none;
  border-width: 1px;
  background-color: #f4f4f4;
  border-color: #cccccc;
  box-sizing: border-box;
  width: 100% !important;
}

.mapWrapper {
  width: 100% !important;
  float: left;
  height: auto;
  position: relative;
  margin-top: 60px;
  box-sizing: border-box;
  margin-bottom: 25px;
}

.map{
  width: 100% !important;
  height: 600px;
  top: 0px !important;
  position: relative !important;
  overflow: hidden;
  border-style: solid;
  border-width: 1px;
  border-color: #cccccc;
  box-sizing: border-box;
}

#BasemapToggle {
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 50;
}

#search {
  display: block;
  position: absolute;
  z-index: 100;
  top: 100px;
  left: 74px;
}

.polygonButton span{
  width: 100px !important;
  height: 30px !important;
  background-color: #c62828 !important;
  border-style: none !important;
  color: #fff !important;
  text-align: center !important;
  line-height: 30px;
}

.submitProjectBtn{
  height: 50px;
  width: 300px;
  float: left;
  border: none;
  box-sizing: border-box;
  padding: 5px;
  background-color: #c62828;
  display: inline-block;
  color: #ffffff;
  margin: 0px;
  cursor: pointer;
  font-size: 16px;
  margin-top: 20px;
}

.projectButton{
  position: relative;
  float: left;
  width: auto;
  height: 60px;
  line-height: 60px;
  margin-right: 50px;
  cursor: pointer;
}