.featureWrapper{
  position: relative;
  float: left;
  width: 100%;
  background-color: #ffffff;
  height: auto;
  min-height: 60px;
  margin-top: 10px;
  box-sizing: border-box;
  padding: 5px;
  text-align: left;
  line-height: 50px;
  text-decoration: none;
  color: #000;
  font-size: 18px;
}

.featureWrapperChecked{
  position: relative;
  float: left;
  width: 100%;
  margin-top: 10px;
  height: auto;
  min-height: 60px;
  line-height: 50px;
  text-align: left;
  background-color: #ffffff;
  box-sizing: border-box;
  padding: 5px;
  color: #000000;
  font-size: 18px;
  outline: none;
  -webkit-box-shadow: inset 0px 0px 5px #cc3300;
  -moz-box-shadow: inset 0px 0px 5px #cc3300;
  box-shadow: inset 0px 0px 5px #cc3300;
}

.featureSelected{
  position: relative;
  float: left;
  width: 25px;
  margin-right: 15px;
  margin-left: 15px;
  height: 50px;
}

.featureSelectedCheckbox{
  position: relative;
  top: 8px;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
  cursor: pointer;
}

.featureStatus{
  position: relative;
  float: left;
  width: auto;
  bottom: 0px;
}

.featureId{
  position: relative;
  float: left;
  width: auto;
  margin-right: 5px;
  height: auto;
}

.featureCoordinatesLine{
  position: relative;
  float: right;
  width: auto;
  margin-left: 5px;
  height: 100%;
  font-size: 12px;
  line-height: 25px;
  text-align: right;
}

.featureCoordinatesPoint{
  position: relative;
  float: right;
  width: auto;
  margin-left: 5px;
  height: 100%;
  font-size: 12px;
  line-height: 50px;
  text-align: right;
}

.featureCarrot{
  position: relative;
  float: right;
  width: auto;
  margin-left: 5px;
  height: 100%;
  font-size: 12px;
  line-height: 25px;
  z-index: 100;
}

.featureCarrot img{
  position: relative;
  height: 20px;
  margin-top: 15px;
  width: auto;
  float: right;
  cursor: pointer;
  z-index: 100;
}

.featureAttributes{
  position: relative;
  float: left;
  width: 100%;
  height: auto;
  min-height: 50px;
  height: auto;
  background-color: #ffffff;
  box-sizing: border-box;
  padding: 5px;
  color: #000000;
  display: none;
  font-size: 12px;
  line-height: 24px;
}

.attributeWrapper{
  position: relative;
  width: 100%;
  float: left;
  height: auto;
  margin-bottom: 5px;
  line-height: 30px;
}

.attributeFieldWrapper{
  position: relative;
  float: left;
  width: 200px;
}

.attributeValueWrapper{
  position: relative;
  float: left;
  width: auto;
}

.updatedAttributesWrapper{
  position: relative;
  float: left;
  width: 50%;
  height: auto;
}

.originalAttributesWrapper{
  position: relative;
  float: left;
  width: 50%;
  height: auto;
}