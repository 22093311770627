.userMenuWrapper{
  position: absolute;
  width: 100%;
  float: left;
  height: auto;
  background-color: #2b2b2b;
  border-style: solid;
  border-width: 1px;
  border-color: #acacac;
  z-index: 100;
  box-sizing: border-box;
  top: 50px;
  display: block;
}

.userMenuWrapper a:hover{
  text-decoration: none !important;
  color: #fff !important;
}