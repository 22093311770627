.projectViewWrapper{
  position: relative;
  width: 100%;
  height: auto;
  text-align: left;
}

.projectViewClose {
  position: relative;
  width: auto;
  height: 60px;
  font-size: 20px;
  line-height: 60px;
  float: right;
  cursor: pointer;
  z-index: 10;
  text-decoration: none;
}

.projectViewClose a{
  text-decoration: none !important;
  color: #000 !important;
}

.projectViewTitle {
  position: relative;
  width: 100%;
  height: 60px;
  border-bottom-style: solid;
  border-width: 2px;
  border-color: rgb(224, 228, 234);
  font-size: 20px;
  line-height: 60px;
}

.projectViewTitle img {
  position: relative;
  float: left;
  margin-top: 15px;
  margin-right: 10px;
}

.projectViewDesc {
  position: relative;
  width: 100%;
  height: auto;
  font-size: 18px;
  margin-top: 10px;
}