/* Style the container */
.container {
    border-radius: 5px;
    background-color: #f2f2f2;
    padding: 0;
  }
  
  /* Floating column for labels: 25% width */
  .col-25 {
    float: right;
    width: 25%;
    margin-top: 30px;
    border-radius: 5px;
    background-color: #f2f2f2;
  }
  
  /* Floating column for inputs: 75% width */
  .col-75 {
    float: left;
    width: 75%;
    margin-top: 30px;
    padding-right: 5px;
    overflow:auto;
    border-radius: 5px;
    background-color: #f2f2f2;
  }


  /* Floating column for labels: 25% width */
  .col-100 {
    float: right;
    width: 100%;
    margin-top: 30px;
    border-radius: 5px;
    background-color: #f2f2f2;
  }

  /* Style inputs */
  input, select {
    width: 100%;
    padding: 12px 20px;
    margin: 8px 0;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 0;
    box-sizing: border-box;
  }
  
  /* Style the submit button */
  input[type=submit] {
    width: 100%;
    background-color: #4CAF50;
    color: white;
    padding: 14px 20px;
    margin: 8px 0;
    border: none;
    border-radius: 0;
    cursor: pointer;
  }
  
  /* Add a background color to the submit button on mouse-over */
  input[type=submit]:hover {
    background-color: #c62828;
  }

  /* Style the submit button */
  .cancel-btn {
    width: 100%;
    background-color:#c62828;
    color: white;
    padding: 14px 20px;
    margin: 8px 0;
    border: none;
    border-radius: 0;
    cursor: pointer;
    
  }


  .panel-header {
    border: #ffffff;
    padding: 15px;
    margin: 10px 0 0 0;
    background-color: #fff;
  }
  
  .panel-header button {
    border: none;
    background: transparent;
  }
  .panel-header button:focus {
    outline: 0px auto -webkit-focus-ring-color;
  }

  .feature-layer{
    background-color: white;
  }


  
  