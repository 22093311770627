.userMenuOption{
  position: relative;
  width: 100%;
  height: auto;
  padding: 5px;
  cursor: pointer;
  box-sizing: border-box;
  text-decoration: none !important;
  color: #fff !important;
}

.userMenuOption a:hover{
  text-decoration: none !important;
  color: #fff !important;
}