@import url(https://fonts.googleapis.com/css?family=Open+Sans:400italic,600italic,700italic,400,600,700);
@import url(https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.mainWrapper{
  position: relative;
  margin: 0 auto;
  text-align: center;
  width: 100%;
  min-height: 100vh;
  height: auto;
  overflow: hidden;
  z-index: 1;
  /*background-color: #EBEBEB;*/
  background-color: rgb(240, 242, 245);
  left: auto;
  top: auto;
  font-family: 'Open Sans';
}

.mainWindow{
	position: relative;
  margin-left: 60px;
  margin-top: 60px;
  padding: 20px;
  box-sizing: border-box;
}

.mainWindow a{
  text-decoration: none;
}

.wrapper{
  position: relative;
  margin: 0 auto;
  text-align: center;
  width: 100%;
  min-height: 100vh;
  height: auto;
  overflow: hidden;
  z-index: 1;
  /*background-color: #FFFFFF;*/
  background-color: rgb(240, 242, 245);
  left: auto;
  top: auto;
  font-family: 'Open Sans';
}

.headerSmall{
  position: relative;
    float: left;
    width: 100%;
    height: 70px;
    text-align: center;
    font-size: 50px;
    color: #fff;
    z-index: 102;
    background-color: #2b2b2b;
    line-height: 70px;
    box-shadow: 0 2px 15px rgb(0 0 0 / 50%);
}

.headerSmall a{
  text-decoration: none;
  color: #fff;
}

.logoSmall{
  position: relative;
  float: left;
  width: auto;
  height: 50px;
  z-index: 10;
  background-repeat: none;
  margin-top: 10px;
  margin-left: 5px;
  line-height: 50px;
  color: #ffffff;
  font-family: 'BebasNeueRegular', sans-serif;
  font-style: normal;
}

.logoSmall img{
  position: relative;
  float: left;
  width: auto;
  height: 100%;
  z-index: 10;
}

.mainMenu{
  position: absolute;
  top: 25px;
  right: 25px;
  width: auto;
  height: auto;
  z-index: 2;
  color: #000000;
}

.menuOption{
  position: relative;
  float: left;
  width: auto;
  height: auto;
  z-index: 2;
  font-size: 18px;
  line-height: 45px;
  padding-left: 20px;
  padding-right: 20px;
  text-decoration: none;
}

.menuRegister{
  position: relative;
  float: left;
  width: 175px;
  height: 40px;
  z-index: 2;
  font-size: 18px;
  line-height: 45px;
  padding-left: 5px;
  padding-right: 5px;
  text-decoration: none;
  cursor: pointer;
}

.menuOption:hover{
  background-color: #454545;
}

.headerActive{
  border-bottom-style: solid;
  border-bottom-width: 3px;
  border-bottom-color: #c62828;
}

.mobileMenu{
  display: none;
}

.mobileMenuWrapper{
  display: none;
}

.mobileMenuOption img{
  width: 15px;
  height: 15px;
}

.subMenu{
  display: none;
  position: absolute;
  width: auto;
  background-color: #454545;
  left: 0px;
  min-width: 250px;
  text-align: left;
  box-sizing: border-box;
  padding: 5px;
}

.subMenuOption{
  position: relative;
  float: left;
  width: 100%;
  height: auto;
  box-sizing: border-box;
  padding: 5px;
}

.subMenuOption:hover{
  background-color: #2b2b2b;
}

.menuOption:hover .subMenu{
  display: block;
}

.registerButton{
  position: relative;
  float: left;
  height: 40px;
  width: 100%;
  box-sizing: border-box;
  /* padding: 5px; */
  background-color: #c62828;
  border-radius: 5px;
}
@media screen and (max-width : 1050px) {
  .mainMenu{
    display: none;
  }

  .mobileMenu{
    display: block;
    background-image: url(/images/menu.png);
    position:absolute;
    width:40px;
    height:40px;
    top: 5px;
    right: 5px;
    margin-right: 10px;
    margin-top: 10px;
    z-index:101;
    background-repeat:no-repeat;
    background-size: 100%;
  }

  .mobileMenuWrapper{
    display: block;
    width: 100%;
    margin-top: 70px;
    height: auto;
    background-color: #2b2b2b;
    box-sizing: border-box;
    padding: 10px;
    text-align: left;
    line-height: 40px;
  }

  .mobileMenuOption{
    width: 100%;
    height: auto;
    font-size: 18px;
    border-bottom: 1px solid #595959;
  }

  .mobileMenuSubOption{
    width: 100%;
    height: auto;
    font-size: 18px;
  }
} 
.mainFooterWrapper{
  position: absolute;
  float: left;
  width: 100%;
  height: auto;
  z-index: 4;
  left: 0px;
  text-align: center;
  bottom: 0;
  margin-top: 50px;
  background-color: #2b2b2b;
  color: #ffffff;
  font-family: "Open Sans";
}

.mainFooterWrapper a{
  text-decoration: none;
  color: #fff;
}

.footerSocialWrapper{
  position: relative;
  float: left;
  width: 30%;
  height: auto;
}

.footerSocialImage{
  position: relative;
  width: 100%;
  height: auto;
  box-sizing: border-box;
  text-align: center;
}

.footerSocialImage img{
  position: relative;
  width: auto;
  height: 50px;
  display: inline-block;
}

.footerSocialLogos{
  position: relative;
  float: left;
  width: 100%;
  height: auto;
  box-sizing: border-box;
}

.footerSocialLogos img{
  position: relative;
  padding: 5px;
}

.footerDownloads{
  position: relative;
  float: left;
  width: 100%;
  height: auto;
  box-sizing: border-box;
}

.footerDownloads img{
  position: relative;
  width: auto;
  max-height: 45px;
}

.footerMenu{
  position: relative;
  float: left;
  width: 40%;
  height: inherit;
  text-decoration: none;
  color: #fff;
}

.footerMenu a{
  text-decoration: none;
  color: #fff;
}

.footerEmpty{
  position: relative;
  float: left;
  width: 30%;
  height: auto;
}

.footerEsri{
  position: relative;
  width: 100%;
  height: auto;
  box-sizing: border-box;
  text-align: center;
}

.footerEsri img{
  position: relative;
  width: auto;
  max-width: 100%;
  max-height: 75px;
  display: inline-block;
}

@media screen and (max-width : 1050px) {
  .footerSocialWrapper{
    width: 100%;
  }

  .footerMenu{
    width: 100%;
    margin-top: 10px;
  }

  .footerEmpty{
    width: 100%;
    margin-top: 10px;
  }

  .footerSpace{
    height: 400px !important;
  }
}
.mainVideoWrapper{
  width: 100%;
  height: auto;
  position: relative;
  overflow: hidden;
}

.heroContent{
  position: absolute;
  top: 40%;
  left: 50px;
  color: #fff;
  font-size: 30px;
  font-family: Segoe UI,SegoeUI,"Helvetica Neue",Helvetica,Arial,sans-serif;
  background-color: rgb(0, 0, 0, 0.5);
  box-sizing: border-box;
  padding: 10px;
  max-width: 50%;
  height: auto;
  max-height: 60%;
  text-align: left;
  line-height: 22px;
  z-index: 100;
  overflow: hidden;
}

.learnMoreBtn{
  background-color: #c62828;
  border: none;
  color: #ffffff;
  height: 50px;
  position: relative;
  max-width: 400px;
  width: 100%;
  margin-bottom: 20px;
  font-size: 20px;
  cursor: pointer;
}

.heroButton{
  box-sizing: border-box;
  padding: 10px;
  font-size: 18px;
  cursor: pointer;
}

.mainVideoWrapper video{
  object-fit: cover;
  width: 100%;
  height: auto;
}

.homePageExplanation{
  position: relative;
  float: left;
  width: 80%;
  margin-left: 10%;
  height: auto;
  margin-top: 20px;
  margin-bottom: 30px;
  text-align: center;
  font-size: 18px;
}

.mainPageSteps{
  z-index: 2;
  position: relative;
  float: left;
  width: 80%;
  height: auto;
  text-align: center;
  margin-left: 10%;
  margin-top: 25px;
}

.mainPageStep{
  z-index: 2;
  position: relative;
  float: left;
  width: 33.33%;
  height: auto;
  box-sizing: border-box;
  padding: 5px;
  border-left-style: solid;
  border-left-width: 1px;
  border-color: #bbbbbb;
}

.mainPageStep1{
  z-index: 2;
  position: relative;
  float: left;
  width: 33.33%;
  height: auto;
  box-sizing: border-box;
  padding: 5px;
  border-left-style: solid;
  border-left-width: 1px;
  border-color: #bbbbbb;
}

.mainPageStep3{
  z-index: 2;
  position: relative;
  float: left;
  width: 33.33%;
  height: auto;
  box-sizing: border-box;
  padding: 5px;
  border-right-style: solid;
  border-right-width: 1px;
  border-color: #bbbbbb;
  border-left-style: solid;
  border-left-width: 1px;
}

.mainPageNewsWrapper{
  position: relative;
  float: left;
  width: 80%;
  min-height: 80px;
  height: auto;
  margin-left: 10%;
  margin-top: 50px;
  text-align: left;
  font-size: 30px;
  color: #c62828;
}

.mainPageExplanation{
  position: relative;
  float: left;
  width: 80%;
  margin-left: 10%;
  height: auto;
  margin-top: 50px;
  margin-bottom: 50px;
}

.newsClipWrapper{
  position: relative;
  float: left;
  width: 100%;
  height: auto;
  text-align: left;
  text-align: center;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 5px 15px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px;
  margin-bottom: 8px;
  color: rgb(76, 83, 93);
  border-radius: 4px;
  box-sizing: border-box;
  padding: 15px;
}

.newsClipWrapper a{
  text-decoration: none;
  color: rgb(76, 83, 93);
}

.footerSpace{
  position: relative;
  float: left;
  height: 200px;
  width: 100%;
}

.newsImage{
  position: relative;
  float: left;
  width: 20%;
  height: auto;
  box-sizing: border-box;
  padding: 5px;
}

.newsImage img{
  position: relative;
  float: left;
  width: 100%;
  height: auto;
}

.newsContent{
  position: relative;
  float: left;
  width: 80%;
  height: auto;
  box-sizing: border-box;
  padding: 5px;
}

.newsTitle{
  position: relative;
  float: left;
  width: 100%;
  font-size: 25px;
  text-align: left;
  font-size: 28px;
}

.newsDate{
  position: relative;
  float: left;
  width: 100%;
  font-size: 25px;
  text-align: left;
  font-size: 14px;
  margin-bottom: 20px;
}

.newsText{
  position: relative;
  float: left;
  width: 100%;
  margin-top: 10px;
  font-size: 16px;
  text-align: left;
}

@media screen and (max-width : 1050px) {
  .mainPageStep{
    width: 100%;
    border-left: none;
    border-right: none;
    border-bottom: 2px solid #bbbbbb;
    margin-bottom: 10px;
  }

  .mainPageStep1{
    width: 100%;
    border-left: none;
    border-right: none;
    border-bottom: 2px solid #bbbbbb;
    margin-bottom: 10px;
  }

  .mainPageStep3{
    width: 100%;
    border-left: none;
    border-right: none;
    border-bottom: 2px solid #bbbbbb;
    margin-bottom: 10px;
  }

  .mainPageNewsWrapper{
    width: 100%;
    margin-left: 0px;
    box-sizing: border-box;
    padding: 10px;
    text-align: center;
  }

  .newsContent{
    width: 100%;
  }

  .newsTitle{
    text-align: center;
  }

  .newsClipWrapper{
    text-align: center;
    height: auto;
  }

  .newsImage{
    position: relative;
    display: block;
    width: 100%;
    max-height: 220px;
    height: auto;
    margin-top: 15px;
    text-align: center;
  }

  .newsImage img{
    max-width: 100%;
    width: auto;
    height: auto;
    max-height: 220px;
    float: none;
  }

  .newsDate{
    text-align: center;
    margin-bottom: 20px;
  }

  .newsText{
    text-align: center;
  }

  @media screen and (max-width : 790px) {
    .heroContent{
      display: none;
    }
  }
} 
.projectWrapper{
  position: relative;
  float: left;
  width: 100%;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 5px 15px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px;
  border-radius: 4px;
  height: 60px;
  cursor: pointer;
  margin-top: 10px;
  box-sizing: border-box;
  padding: 5px;
  text-align: left;
  line-height: 50px;
  text-decoration: none;
  color: #000;
  font-size: 18px;
}

.projectStatus {
  position: relative;
  float: left;
  width: 10%;
  bottom: 0px;
}

.projectStatus img {
  position: relative;
  float: left;
  width: 28px;
  height: 31px;
  margin-top: 10px;
  margin-left: 15px;
}

.ticketId{
  position: relative;
  float: left;
  width: 10%;
  height: 60px;
  overflow: hidden;
  cursor: pointer;
  box-sizing: border-box;
  padding-right: 10px;
}

.projectName {
  position: relative;
  float: left;
  width: 30%;
  height: 60px;
  overflow: hidden;
  cursor: pointer;
  box-sizing: border-box;
  padding-right: 10px;
}

.projectLocation {
  position: relative;
  float: left;
  width: 15%;
  cursor: pointer;
}

.projectStartDate {
  position: relative;
  float: left;
  width: 10%;
  cursor: pointer;
}

.projectEndDate {
  position: relative;
  float: left;
  width: 10%;
  cursor: pointer;
}

.projectResponses {
  position: relative;
  float: left;
  width: 15%;
  text-align: right;
  cursor: pointer;
}

a{
  text-decoration: none;
}
.projectsFilterWrapper{	
	position: relative;
	width: 100%;
	height: 20px;
	margin-top: 30px;
	color: #6d6d6d;
	text-align: left;
	box-sizing: border-box;
	padding: 5px;
	line-height: 20px;
	font-size: 20px;
}

.projectsFilterWrapper img{
    position: relative;
    height: 20px;
    line-height: 20px;
    width: auto;
    display: inline-block;
}

.projectsFilterStatus{
	position: relative;
    float: left;
    width: 10%;
    bottom: 0px;
    cursor: pointer;
}

.projectsFilterId{
    position: relative;
    float: left;
    width: 10%;
    bottom: 0px;
    cursor: pointer;
}

.projectsFilterName{
	position: relative;
    float: left;
    width: 30%;
    bottom: 0px;
    cursor: pointer;
}

.projectsFilterLocation{
	position: relative;
    float: left;
    width: 15%;
    bottom: 0px;
    cursor: pointer;
}

.projectsFilterStart{
	position: relative;
    float: left;
    width: 10%;
    bottom: 0px;
    cursor: pointer;
}

.projectsFilterEnd{
	position: relative;
    float: left;
    width: 10%;
    bottom: 0px;
    cursor: pointer;
}

.projectsFilterResponses{
	position: relative;
    float: left;
    width: 15%;
    bottom: 0px;
    cursor: pointer;
    text-align: right;
}
.projectsHeaderWrapper{
    position: relative;
    width: 100%;
    height: 60px;
    border-bottom-style: solid;
    border-width: 2px;
    border-color: rgb(224, 228, 234) !important;
    font-size: 24px;
}

.projectsHeaderText{
    position: relative;
    float: left;
    width: auto;
    height: 60px;
    line-height: 60px;
    margin-right: 50px;
    border-bottom-style: solid;
    border-width: 2px;
    border-color: #C62828;
}

.projectsHeaderSearch{
    position: absolute;
    height: 40px;
    right: 0px;
    top: 5px;
    width: auto;
    font-size: 16px;
}

input.projectsHeaderSearch {
    position: relative;
    width: 250px;
    height: 30px;
    line-height: 30px;
    display: inline-block;
    margin-top: 10px;
    border: none;
    box-sizing: border-box;
    padding-left: 5px;
    padding: 0px 8px;
    border-width: 1px;
    border-style: solid;
    border-color: rgb(154, 168, 187);
    -webkit-border-image: none;
            border-image: none;
    -webkit-border-image: initial;
            border-image: initial;
    border-radius: 4px;
}

.projectsHeaderSearchNew{
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    width: 350px;
    float: left;
    height: 30px;
    margin-top: 15px;
}

.projectsHeaderSearch select{
    position: relative;
    float: left;
    width: auto;
    height: 30px;
    line-height: 30px;
    display: inline-block;
    margin-top: 15px;
    margin-right: 10px;
    padding: 0px;
}

.projectsHeaderSearch label{
    float: left;
    margin-right: 1px;
    margin-top: 17px;
}

#projectsHeaderSearchNew .input-group-prepend{
    height: 100%;
}

#projectsHeaderSearchNew .form-control{
    height: 100%;
    margin-top: 0px;
}
.spinnerWrapper{
	position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: rgba( 255, 255, 255, .8 ) url(https://i.stack.imgur.com/FhHRx.gif) 50% 50% no-repeat;
}
a{
  text-decoration: none;
}

.noProjects{
  position: relative;
  float: left;
  width: 80%;
  margin-left: 10%;
  height: auto;
  color: #000;
  box-sizing: border-box;
  padding: 15px;
  margin-top: 20px;
  box-shadow:  0 0 10px  rgba(0,0,0,0.6);
  -moz-box-shadow: 0 0 10px  rgba(0,0,0,0.6);
  -webkit-box-shadow: 0 0 10px  rgba(0,0,0,0.6);
  -o-box-shadow: 0 0 10px  rgba(0,0,0,0.6);
  border-radius: 15px;
  background-color: #fff3cd;
  border-color: #ffeeba;
}
.map{
  width: 100% !important;
  height: 600px;
  top: 0px !important;
  position: relative !important;
  overflow: hidden;
  border-style: solid;
  border-width: 1px;
  border-color: #cccccc;
  box-sizing: border-box;
}

#toolbarDiv {
    position: absolute;
    top: 15px;
    right: 95px;
    cursor: default;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
 }
.responseWrapper{
	position: relative;
	width: 100%;
	float: left;
	height: auto;
}

.allResponseWrapper{
	position: relative;
	width: 100%;
	float: left;
	height: auto;
	margin-bottom: 15px;
}

.shapefileBtn{
	position: relative;
	margin-right: 10px;
	text-align: center;
	margin-top: 5px;
}

.responseFileWrapper{
	position: relative;
	float: left;
	width: 100%;
	height: auto;
}
.projectViewWrapper{
  position: relative;
  width: 100%;
  height: auto;
  text-align: left;
}

.projectViewClose {
  position: relative;
  width: auto;
  height: 60px;
  font-size: 20px;
  line-height: 60px;
  float: right;
  cursor: pointer;
  z-index: 10;
  text-decoration: none;
}

.projectViewClose a{
  text-decoration: none !important;
  color: #000 !important;
}

.projectViewTitle {
  position: relative;
  width: 100%;
  height: 60px;
  border-bottom-style: solid;
  border-width: 2px;
  border-color: rgb(224, 228, 234);
  font-size: 20px;
  line-height: 60px;
}

.projectViewTitle img {
  position: relative;
  float: left;
  margin-top: 15px;
  margin-right: 10px;
}

.projectViewDesc {
  position: relative;
  width: 100%;
  height: auto;
  font-size: 18px;
  margin-top: 10px;
}


.map{
  width: 100% !important;
  height: 600px;
  top: 0px !important;
  position: relative !important;
  overflow: hidden;
  border-style: solid;
  border-width: 1px;
  border-color: #cccccc;
  box-sizing: border-box;
}
.shapefile{
	position: relative;
	width: 100%;
	height: auto;
	float: left;
}

.kmluploadwrapper{
    text-align: center;
    /* background-color: #4c96d3; */
    padding: 30px 6px;
    margin: 15px 0px 15px 0px;
    font-size: 14px;
    line-height: 1.42857143;
    color: #1A4882;
    background-image: none;
    background-color: rgba(238, 238, 238, 0.80);
    /* background-color: #d9edf7; */
    border: 2px dotted rgb(198,40,40,0.99);
    border-radius: 6px;
    margin-top: 10px;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.3);
}
.fileTypeWrapper{
  position: relative;
  width: 100%;
  height: 40px;
  box-sizing: border-box;
  line-height: 40px;
}
.kmluploadwrapper{
    text-align: center;
    /* background-color: #4c96d3; */
    padding: 30px 6px;
    margin: 15px 0px 15px 0px;
    font-size: 14px;
    line-height: 1.42857143;
    color: #1A4882;
    background-image: none;
    background-color: rgba(238, 238, 238, 0.80);
    /* background-color: #d9edf7; */
    border: 2px dotted rgb(198,40,40,0.99);
    border-radius: 6px;
    margin-top: 10px;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.3);
}

.modelWrapper{
    position: relative;
    float: left;
    margin-top: 10px;
    width: 100%;
    height: 500px;
}

.submitProjectInactiveBtn {
    height: 50px;
    width: 300px;
    float: left;
    border: none;
    box-sizing: border-box;
    padding: 5px;
    background-color: #eb6969;
    display: inline-block;
    color: #ffffff;
    margin: 0px;
    font-size: 16px;
    margin-top: 20px;
}

.submitNoticeWrapper{
    position: relative;
    float: left;
    width: 100%;
    height: auto;
}

/*.modelWrapper canvas{
    width: 100% !important;
    height: 500px !important;
}*/

/*.modelWrapper div{
    position: relative;
    padding-left: 0;
    padding-right: 0;
    margin-left: auto;
    margin-right: auto;
}*/
/*@import url('https://js.arcgis.com/3.28/esri/css/esri.css');
@import url('https://js.arcgis.com/3.28/dijit/themes/nihilo/nihilo.css');*/

.createProjectWrapper {
  position: relative;
  box-sizing: border-box;
  text-align: left !important;
  height: auto;
}

.createProjectHeader {
  position: relative;
  width: 100%;
  height: 60px;
  border-bottom-style: solid;
  border-width: 2px;
  border-color: rgb(224, 228, 234) !important;
  font-size: 24px;
}

.projectButtonSelected {
  position: relative;
  float: left;
  width: auto;
  height: 60px;
  line-height: 60px;
  margin-right: 50px;
  border-bottom-style: solid;
  border-width: 2px;
  border-color: #C62828;
  cursor: pointer;
}

.createProjectInfoWrapper {
  position: relative;
  float: left;
  width: 100%;
  height: auto;
  margin-top: 10px;
  margin-bottom: 50px;
}

input.projectInputField {
  height: 30px;
  font-size: 15px;
  line-height: 30px;
  display: inline-block;
  width: 70%;
  padding-left: 5px;
}

textarea.projectInputArea {
  min-height: 150px;
  font-size: 15px;
  line-height: 30px;
  display: inline-block;
  max-width: 90%;
  min-width: 90%;
  font-family: Arial;
  padding-left: 5px;
}

input.projectInputDate {
  height: 30px;
  font-size: 15px;
  line-height: 30px;
  display: inline-block;
  width: 120px;
  padding-left: 5px;
}

select.projectSelect {
  height: 30px;
  font-size: 15px;
  line-height: 30px;
  display: inline-block;
  width: auto;
  max-width: 100%;
  padding: 0px;
}

.mapHeader {
  height: 80px;
  overflow: auto;
  padding: 0.5em;
  position: relative !important;
  border-left-style: solid;
  border-right-style: solid;
  border-top-style: solid;
  border-bottom-style: none;
  border-width: 1px;
  background-color: #f4f4f4;
  border-color: #cccccc;
  box-sizing: border-box;
  width: 100% !important;
}

.mapWrapper {
  width: 100% !important;
  float: left;
  height: auto;
  position: relative;
  margin-top: 60px;
  box-sizing: border-box;
  margin-bottom: 25px;
}

.map{
  width: 100% !important;
  height: 600px;
  top: 0px !important;
  position: relative !important;
  overflow: hidden;
  border-style: solid;
  border-width: 1px;
  border-color: #cccccc;
  box-sizing: border-box;
}

#BasemapToggle {
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 50;
}

#search {
  display: block;
  position: absolute;
  z-index: 100;
  top: 100px;
  left: 74px;
}

.polygonButton span{
  width: 100px !important;
  height: 30px !important;
  background-color: #c62828 !important;
  border-style: none !important;
  color: #fff !important;
  text-align: center !important;
  line-height: 30px;
}

.submitProjectBtn{
  height: 50px;
  width: 300px;
  float: left;
  border: none;
  box-sizing: border-box;
  padding: 5px;
  background-color: #c62828;
  display: inline-block;
  color: #ffffff;
  margin: 0px;
  cursor: pointer;
  font-size: 16px;
  margin-top: 20px;
}

.projectButton{
  position: relative;
  float: left;
  width: auto;
  height: 60px;
  line-height: 60px;
  margin-right: 50px;
  cursor: pointer;
}
.userMenuOption{
  position: relative;
  width: 100%;
  height: auto;
  padding: 5px;
  cursor: pointer;
  box-sizing: border-box;
  text-decoration: none !important;
  color: #fff !important;
}

.userMenuOption a:hover{
  text-decoration: none !important;
  color: #fff !important;
}
.userMenuWrapper{
  position: absolute;
  width: 100%;
  float: left;
  height: auto;
  background-color: #2b2b2b;
  border-style: solid;
  border-width: 1px;
  border-color: #acacac;
  z-index: 100;
  box-sizing: border-box;
  top: 50px;
  display: block;
}

.userMenuWrapper a:hover{
  text-decoration: none !important;
  color: #fff !important;
}
.topRail{
  position: fixed;
  margin-top: 0px;
  width: 100%;
  height: 50px;
  background-color: #2b2b2b;
  z-index: 100;
  color: #fff;
  top: 0px;
  left: 0px;
  box-shadow: 0 2px 15px rgb(0 0 0 / 50%);
}

.logo{
  position: absolute;
  width: 50px;
  height: 50px;
  top: 0px;
  left: 0px;
  box-shadow: none;
}

.logo img{
  position: relative;
  width: 40px;
  height: 40px;
  margin-left: 5px;
  margin-top: 5px;
}

.accountInfo {
  position: absolute;
  right: 0px;
  top: 0px;
  width: auto;
  height: 100%;
  border-left-style: solid;
  border-width: 1px;
  border-color: #acacac;
}

.accountInfo img {
  position: relative;
  float: left;
  left: 0px;
  top: 0px;
  width: 30px;
  height: 24px;
  margin-top: 13px;
  margin-right: 13px;
  margin-left: 13px;
}

.usernameHeader {
  position: relative;
  float: left;
  width: auto !important;
  height: 100%;
  line-height: 50px;
}

.accountInfoCarrot {
  position: relative;
  width: 50px;
  float: left;
  height: 100%;
  cursor: pointer;
}

.companyName {
  position: relative;
  margin: 0 auto;
  line-height: 50px;
  width: 50%;
}
.leftRail{
  position: fixed;
  margin-top: 50px;
  width: 50px;
  height: 100%;
  background-color: #2b2b2b;
  z-index: 100;
  top: 0px;
  left: 0px;
}

.fullLeftRail{
  position: fixed;
  margin-top: 50px;
  width: 250px;
  height: 100%;
  background-color: #2b2b2b;
  z-index: 100;
  top: 0px;
  left: 0px;
}

.leftRailMenu {
	position: absolute;
	float: left;
	width: 100%;
	height: 100%;
	margin-top: 20px;
}

.leftRailMenu img{
	position: relative;
    float: left;
    width: 25px;
    height: auto;
    margin-left: 12px;
}

.menuOptionText {
    position: relative;
    width: 200px;
    float: left;
    margin-left: 10px;
    text-align: left;
    color: #ffffff;
}

.menuOptionText a{
	text-decoration: none;
	color: #ffffff;
}

.menuImageWrapper{
	position: relative;
	width: 100%;
	height: 50px;
}

.active{
	border-left: 2px;
	border-left-color: #c62828;
    border-left-style: solid;
    height: 75px;
    position:relative;
    float:left;
}
.childActive{
	border-left: inherit;
	border-left-color: inherit;
    border-left-style: inherit;
    height: inherit;
    position:inherit;
    float: inherit;
}

.childActiveSmall {
    float: left;
    width: 50px;
    height: 25px;
    margin-top: 20px;
    margin-bottom: 20px;
}

.childActiveFull{
	float: left;
	width: 250px;
	height: 25px;
	margin-top: 20px;
	margin-bottom: 20px;
}

.logoutLeftMenuSmall {
    position: fixed;
    bottom: 20px;
    left: 0px;
    width: 50px;
    height: 25px;
    margin-top: 20px;
    cursor: pointer;
}

.logoutLeftMenuFull {
    position: fixed;
    bottom: 20px;
    left: 0px;
    width: 250px;
    height: 25px;
    margin-top: 20px;
    cursor: pointer;
}
.pageTitle{
	position: relative;
    float: left;
    width: 80%;
    margin-left: 10%;
    color: #c62828;
    font-size: 35px;
    text-align: left;
    margin-top: 15px;
    border-bottom-style: solid;
    border-color: rgb(224, 228, 234);
    border-width: 2px;
}

.loginFormWrapper{
    position: relative;
    float: left;
    width: 100%;
    height: auto;
    margin-top: 25px;
    text-align: center;
}

.loginForm{
	position: relative;
	float: left;
	height: auto;
	width: 100%;
}

.loginAuth0{
    position: relative;
    float: left;
    height: auto;
    width: 100%;
    text-align: middle;
}

.loginAuth0 button{
    background-color: #c62828;
    border: none;
    color: #ffffff;
    height: 50px;
    position: relative;
    width: 50%;
    margin-bottom: 20px;
    font-size: 20px;
    cursor: pointer;
}

.loginAuth0 input{
    position: relative;
    width: 50%;
    height: 30px;
    margin-bottom: 20px;
    font-size: 20px;
}

.loginForm input{
	position: relative;
    width: 50%;
    height: 30px;
    margin-bottom: 20px;
    font-size: 20px;
}

.loginForm button{
	background-color: #c62828;
    border: none;
    color: #ffffff;
    height: 50px;
    position: relative;
    width: 50%;
    margin-bottom: 20px;
    font-size: 20px;
    cursor: pointer;
}

/*.createAccount button{
  background-color: #c62828;
  border: none;
  color: #ffffff;
  height: 50px;
  position: relative;
  max-width: 400px;
  width: 100%;
  margin-bottom: 20px;
  font-size: 20px;
  cursor: pointer;
}

.createAccount{
  text-align: left;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 5px 15px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px;
  margin-bottom: 8px;
  width: 100%;
  min-width: 100%;
  color: rgb(76, 83, 93);
  border-radius: 4px;
  box-sizing: border-box;
  padding: 15px;
}*/

@media screen and (max-width : 750px) {
  .loginAuth0 input{
    width: 100%;
  }

  .loginAuth0 button{
    width: 100%;
  }
}

.requestWrapper{
  position: relative;
  float: left;
  width: 100%;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 5px 15px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px;
  border-radius: 4px;
  height: 60px;
  cursor: pointer;
  margin-top: 10px;
  box-sizing: border-box;
  padding: 5px;
  text-align: left;
  line-height: 50px;
  text-decoration: none;
  color: #000;
  font-size: 18px;
}

.requestStatus {
  position: relative;
  float: left;
  width: 10%;
  bottom: 0px;
}

.requestStatus img {
  position: relative;
  float: left;
  width: 28px;
  height: 31px;
  margin-top: 10px;
  margin-left: 15px;
}

.requestId{
  position: relative;
  float: left;
  width: 15%;
  height: 60px;
  overflow: hidden;
  cursor: pointer;
  box-sizing: border-box;
  padding-right: 10px;
}

.requestName {
  position: relative;
  float: left;
  width: 20%;
  height: 60px;
  overflow: hidden;
  cursor: pointer;
  box-sizing: border-box;
  padding-right: 10px;
}

.requestLocation {
  position: relative;
  float: left;
  width: 10%;
  cursor: pointer;
}

.requestUtilityType{
  position: relative;
  float: left;
  width: 13%;
  cursor: pointer;
}

.requestStartDate {
  position: relative;
  float: left;
  width: 11%;
  cursor: pointer;
}

.requestEndDate {
  position: relative;
  float: left;
  width: 11%;
  cursor: pointer;
}

.requestStatus {
  position: relative;
  float: left;
  width: 10%;
  text-align: right;
  cursor: pointer;
}

a{
  text-decoration: none;
}
.requestsFilterWrapper{	
	position: relative;
	width: 100%;
	height: 20px;
	margin-top: 30px;
	color: #6d6d6d;
	text-align: left;
	box-sizing: border-box;
	padding: 5px;
	line-height: 20px;
	font-size: 20px;
}

.requestsFilterWrapper img{
    position: relative;
    height: 20px;
    line-height: 20px;
    width: auto;
    display: inline-block;
    top: 3px;
}

.requestsFilterStatus{
	position: relative;
    float: left;
    width: 10%;
    bottom: 0px;
    cursor: pointer;
}

.requestsFilterId{
    position: relative;
    float: left;
    width: 15%;
    bottom: 0px;
    cursor: pointer; 
}

.requestsFilterName{
	position: relative;
    float: left;
    width: 20%;
    bottom: 0px;
    cursor: pointer;
}

.requestsFilterLocation{
	position: relative;
    float: left;
    width: 10%;
    bottom: 0px;
    cursor: pointer;
}

.requestsFilterUtilityType{
    position: relative;
    float: left;
    width: 13%;
    bottom: 0px;
    cursor: pointer;
}

.requestsFilterStart{
	position: relative;
    float: left;
    width: 11%;
    bottom: 0px;
    cursor: pointer;
}

.requestsFilterEnd{
	position: relative;
    float: left;
    width: 11%;
    bottom: 0px;
    cursor: pointer;
}

.requestsFilterResponses{
	position: relative;
    float: left;
    width: 10%;
    bottom: 0px;
    cursor: pointer;
    text-align: right;
}
.requestsHeaderWrapper{
    position: relative;
    width: 100%;
    height: 60px;
    border-bottom-style: solid;
    border-width: 2px;
    border-color: rgb(224, 228, 234) !important;
    font-size: 24px;
}

.requestsHeaderText{
    position: relative;
    float: left;
    width: auto;
    height: 60px;
    line-height: 60px;
    margin-right: 50px;
    border-bottom-style: solid;
    border-width: 2px;
    border-color: #C62828;
    cursor: pointer;
}

.requestsHeaderSearch{
    position: absolute;
    height: 40px;
    right: 0px;
    top: 5px;
    width: auto;
    font-size: 16px;
}

input.requestsHeaderSearch {
    position: relative;
    width: 250px;
    height: 30px;
    line-height: 30px;
    display: inline-block;
    margin-top: 10px;
    border: none;
    box-sizing: border-box;
    padding-left: 5px;
    padding: 0px 8px;
    border-width: 1px;
    border-style: solid;
    border-color: rgb(154, 168, 187);
    -webkit-border-image: none;
            border-image: none;
    -webkit-border-image: initial;
            border-image: initial;
    border-radius: 4px;
}

.requestsHeaderSearch select{
    position: relative;
    float: left;
    width: auto;
    height: 30px;
    line-height: 30px;
    display: inline-block;
    margin-top: 15px;
    margin-right: 10px;
    padding: 0px;
}

.requestsHeaderSearch label{
    float: left;
    margin-right: 1px;
    margin-top: 17px;
}
a{
  text-decoration: none;
}

.allRequestsWrapper{
	position: relative;
	float: left;
	height: auto;
	width: 100%;
	margin-bottom: 25px;
}

.requestViewWrapper{
  position: relative;
  width: 100%;
  height: auto;
  text-align: left;
}

.requestResponseWrapper{
  position: relative;
  float: left;
  width: 100%;
  height: auto;
  margin-bottom: 20px;
}

.requestViewClose {
  position: relative;
  width: auto;
  height: 60px;
  font-size: 20px;
  line-height: 60px;
  float: right;
  cursor: pointer;
  z-index: 10;
  text-decoration: none;
}

.requestViewClose a{
  text-decoration: none !important;
  color: #000 !important;
}

.requestViewTitle {
  position: relative;
  width: 100%;
  height: 60px;
  border-bottom-style: solid;
  border-width: 2px;
  border-color: rgb(224, 228, 234);
  font-size: 20px;
  line-height: 60px;
}

.requestViewTitle img {
  position: relative;
  float: left;
  margin-top: 15px;
  margin-right: 10px;
}

.requestViewDesc {
  position: relative;
  width: 100%;
  height: auto;
  font-size: 18px;
  margin-top: 10px;
}

.approveBtn{
  background-color: #4CAF50;
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  cursor: pointer;
  margin-right: 10px;
  position: relative;
  float: left;
}

.denyBtn{
  background-color: #f44336;
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  cursor: pointer;
  position: relative;
  float: left;
}

.requestResponseText {
  width: 100%;
  position: relative;
  float: left;
  resize: none;
}

.attachBtnWrapper{
  display: inline-block;
  height: 35px;
  width: 35px;
  margin-left: 15px;
  box-shadow:inset 0px 1px 0px 0px #ffffff;
  background:-webkit-gradient(linear, left top, left bottom, color-stop(5%, #ffffff), to(#cccccc));
  background:linear-gradient(to bottom, #ffffff 5%, #cccccc 100%);
  background-color:#ffffff;
  border:1px solid #000000;
  display:inline-block;
  cursor:pointer;
  color:#616161;
  font-family:Arial;
  font-size:13px;
  font-weight:bold;
  text-decoration:none;
  float: left;
}

.attachBtn{
  position: relative;
  height: 50%;
  width: 50%;
  margin-left: 25%;
  margin-top: 25%;
}

.attachImgWrapper{
  position: relative;
  width: 100%;
  height: 100%;
}

.requestButtons{
  position: relative;
  float: left;
  width: 100%;
  height: auto;
  margin-bottom: 10px;
}

.uploadOptionsWrapper{
  position: relative;
  left: 35px;
  top: -34px;
  width: 200px;
  height: auto;
  box-shadow:inset 0px 1px 0px 0px #ffffff;
  background-color:#ffffff;
  cursor:pointer;
  color:#616161;
  font-family:Arial;
  font-size:13px;
  font-weight:bold;
  text-decoration:none;
  float: left;
}

.uploadOption{
  position: relative;
  float: left;
  width: 100%;
  height: 25px;
  line-height: 25px;
  text-align: center;
  cursor: pointer;
}

.fileInput{
  width: 100%;
  height: 25px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  cursor: pointer;
}

.fileInput + label{
  width: 100%;
  height: 25px;
  text-align: center;
  cursor: pointer;
  line-height: 25px;
  margin-bottom: 0px !important;
}

.uploadedFilesWrapper{
  position: relative;
  width: 100%;
  min-height: 75px;
  height: auto;
  background-color: #fff;
  float: left;
  margin-bottom: 10px;
  box-sizing: border-box;
  padding: 5px;
}

.singleFileWrapper{
  position: relative;
  float: left;
  min-width: 300px;
  width: auto;
  height: 80px;
  box-sizing: border-box;
  padding-left: 5px;
  line-height: 75px;
  background-color: #f5f5f5;
  border: 1px solid #c4c4c4;
  margin-left: 5px;
  margin-top: 5px;
  margin-bottom: 5px;
}

.fileDelete{
  position: absolute;
  top: 5px;
  right: 5px;
  width: 25px;
  height: 25px;
  background-color: #fff;
  border: 1px solid #000;
  cursor: pointer;
}

.fileDelete img{
  position: relative;
  float: left;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.fileName{
  position: relative;
  width: 100%;
  height: 40px;
  box-sizing: border-box;
  line-height: 40px;
}
.pageHeader{
  position: relative;
  width: 100%;
  height: 60px;
  border-bottom-style: solid;
  border-width: 2px;
  border-color: rgb(224, 228, 234);
  font-size: 20px;
  line-height: 60px;
  text-align: left;
  float: left;
}

.profileBody{
  position: relative;
  float: left;
  margin-top: 10px;
  width: 100%;
  height: auto;
  text-align: left;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 5px 15px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px;
  border-radius: 4px;
  box-sizing: border-box;
  padding: 15px;
  margin-bottom: 25px;
}

input.companyInput {
  height: 30px;
  font-size: 15px;
  line-height: 30px;
  display: inline-block;
  max-width: 350px;
  width: 100%;
  padding-left:5px;
}
.catalogInputField{
  height: 30px;
  font-size: 15px;
  line-height: 30px;
  display: none;
  width: 70%;
}

.featureServiceColors{
	position: relative;
	float: left;
	width: 100%;
	height: auto;
	line-height: 50px;
}

.featureServiceColor{
	position: relative;
	float: left;
	width: 100%;
	height: auto;
	margin-bottom: 5px;
	box-sizing: border-box;
	padding: 10px;
}

.featureServiceColorImgWrapper{
	position: relative;
	float: left;
	width: 50px;
	height: 50px;
	background-color: #000000;
	margin-right: 20px;
}

.colorPickerFullWrapper{
	position: fixed;
    width: 100%;
    height: 100%;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    z-index: 1000;
    background: rgb(18 18 18 / 60%) 50% 50% no-repeat;
}

.colorPickerWrapper{
	position: fixed;
	top: 50%;
	left: 50%;
	/* bring your own prefixes */
	-webkit-transform: translate(-50%, -50%);
	        transform: translate(-50%, -50%);
	z-index: 1000;
}

.conditionDelete{
	width: auto;
	float: right;
}

.fullWidth{
	float: left;
	width: 100%;
}
.featureServiceWrapper{
  position: relative;
  float: left;
  text-align: left;
  width: 100%;
  height: auto;
}

.selectionHeader {
  position: relative;
  width: 100%;
  height: 60px;
  border-bottom-style: solid;
  border-width: 2px;
  border-color: #acacac;
  font-size: 20px;
  line-height: 60px;
  color: #c62828;
  cursor: pointer;
}

.selectFields{
  position: relative;
  width: 100%;
  height: auto;
  float: left;
}

.selectFieldOption{
  position: relative;
  width: 100%;
  height: auto;
  float: left;
}

.selectFieldsHeader{
  position: relative;
  width: 100%;
  height: auto;
  float: left;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: rgb(224, 228, 234);
}

.selectionHeader img {
  position: relative;
  height: 20px;
  margin-top: 20px;
  width: auto;
  float: left;
  cursor: pointer;
}

.layerHeader{
  position: relative;
  float: left;
  width: 100%;
  line-height: 50px;
  font-weight: bold;
}

.layerSelection{
  position: relative;
  float: left;
  width: 100%;
  height: auto;
}

.layerOptionWrapper{
  position: relative;
  float: left;
  width: 100%;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 5px 15px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px;
  border-radius: 4px;
  height: auto;
  cursor: pointer;
  margin-top: 10px;
  box-sizing: border-box;
  padding: 5px;
  text-align: left;
  line-height: 50px;
}

.featureServiceDropdownWrapper{
  display: none;
  background-color: #ffffff;
  height: auto;
  position: relative;
  width: 100%;
  float: left;
  padding: 10px;
  box-sizing: border-box;
}

.featureLayerShapefile{
  position: relative;
  margin-left: 10px;
}

.featureServiceName{
  position: relative;
  margin-left: 0px;
  margin-right: 0px;
}

.featureServiceName10{
  width: 10% !important;
  line-height: normal !important;
}

.featureServiceNameInput{
  line-height: normal !important;
  width: 90% !important;
}

.featureLayerName{
  position: relative;
  margin-left: 0px;
  margin-right: 0px;
}

.featureFieldName{
  position: relative;
  margin-left: 0px;
  margin-right: 0px;
}


.username {
  position: relative;
  float: left;
  width: 20%;
  bottom: 0px;
  cursor: pointer;
}

.contactId{
  position: relative;
  float: left;
  width: 15%;
  bottom: 0px;
  text-align: left;
}

.userLicense {
  position: relative;
  float: left;
  width: 20%;
  bottom: 0px;
}

.userRole {
  position: relative;
  float: left;
  width: 15%;
  bottom: 0px;
  cursor: pointer;
  text-align: left;
}

.userRoleSelect{
  padding-left:5px;
}

.userUpdate {
  position: relative;
  float: left;
  width: 30%;
  bottom: 0px;
  cursor: pointer;
  text-align: right;
}

.userWrapper {
  position: relative;
  float: left;
  width: 100%;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 5px 15px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px;
  border-radius: 4px;
  min-height: 60px;
  height: auto;
  cursor: pointer;
  margin-top: 10px;
  box-sizing: border-box;
  padding: 15px;
  text-align: left;
  line-height: 50px;
}

.userInfoWrapper{
  position: relative;
  float: left;
  width: 100%;
  height: auto;
}

.userUpdate button{
  margin-left: 10px;
}

.userRolesWrapper{
  position: relative;
  float: left;
  width: 100%;
  height: auto;
}

.userRoleWrapper{
  position: relative;
  float: left;
  width: 100%;
  line-height: 30px;
}

.contactInfoWrapper{
  line-height: 30px !important;
}
.userFilter{
  position: relative;
  width: 100%;
  height: 20px;
  color: #6d6d6d;
  text-align: left;
  box-sizing: border-box;
  padding: 5px;
  line-height: 20px;
}

.addUserBtn{
  margin-left: 25px;
}

.userFilterUsername {
  position: relative;
  float: left;
  width: 20%;
  bottom: 0px;
  cursor: pointer;
}

.userFilterContactId {
  position: relative;
  float: left;
  width: 15%;
  bottom: 0px;
  cursor: pointer;
  text-align: left;
}

.userFilterLicense {
  position: relative;
  float: left;
  width: 20%;
  bottom: 0px;
  cursor: pointer;
}

.userFilterRole {
  position: relative;
  float: left;
  width: 15%;
  bottom: 0px;
  cursor: pointer;
  text-align: left;
}

.userFilter img {
  position: relative;
  height: 20px;
  line-height: 20px;
  width: auto;
  display: inline-block;
}

.userViewWrapper{
  position: relative;
  float: left;
  width: 100%;
  height: auto;
}

.numLicenses{
  position: relative;
  float: right;
  width: auto;
  height: 100%;
  text-align: right;
  font-style: italic;
  font-size: 12px;
}

.plainBody{
  position: relative;
  float: left;
  margin-top: 10px;
  width: 100%;
  height: auto;
  text-align: left;
  box-sizing: border-box;
  padding: 15px;
}

.editSessionWrapper{
  position: relative;
  float: left;
  width: 100%;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 5px 15px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px;
  border-radius: 4px;
  height: 60px;
  cursor: pointer;
  margin-top: 10px;
  box-sizing: border-box;
  padding: 5px;
  text-align: left;
  line-height: 50px;
  text-decoration: none;
  color: #000;
  font-size: 18px;
}

.editSessionId {
  position: relative;
  float: left;
  width: 25%;
  height: auto;
  overflow: ellipsis;
  text-align: left;
}

.editSessionName{
  position: relative;
  float: left;
  width: 25%;
  height: auto;
  overflow: ellipsis;
  text-align: left;
}

.editSessionCreator{
  position: relative;
  float: left;
  width: 25%;
  height: auto;
  overflow: ellipsis;
  text-align: left;
}

.editSessionDate {
  position: relative;
  float: left;
  width: 25%;
  height: auto;
  overflow: ellipsis;
  text-align: right;
}


a{
  text-decoration: none;
}
.map{
  width: 100% !important;
  height: 600px;
  top: 0px !important;
  position: relative !important;
  overflow: hidden;
  border-style: solid;
  border-width: 1px;
  border-color: #cccccc;
  box-sizing: border-box;
}

.editSessionMapWrapper{
	width: 100% !important;
    height: auto;
    position: relative;
    float: left;
    box-sizing: border-box;
    margin-bottom: 25px;
}
.featureWrapper{
  position: relative;
  float: left;
  width: 100%;
  background-color: #ffffff;
  height: auto;
  min-height: 60px;
  margin-top: 10px;
  box-sizing: border-box;
  padding: 5px;
  text-align: left;
  line-height: 50px;
  text-decoration: none;
  color: #000;
  font-size: 18px;
}

.featureWrapperChecked{
  position: relative;
  float: left;
  width: 100%;
  margin-top: 10px;
  height: auto;
  min-height: 60px;
  line-height: 50px;
  text-align: left;
  background-color: #ffffff;
  box-sizing: border-box;
  padding: 5px;
  color: #000000;
  font-size: 18px;
  outline: none;
  box-shadow: inset 0px 0px 5px #cc3300;
}

.featureSelected{
  position: relative;
  float: left;
  width: 25px;
  margin-right: 15px;
  margin-left: 15px;
  height: 50px;
}

.featureSelectedCheckbox{
  position: relative;
  top: 8px;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
  cursor: pointer;
}

.featureStatus{
  position: relative;
  float: left;
  width: auto;
  bottom: 0px;
}

.featureId{
  position: relative;
  float: left;
  width: auto;
  margin-right: 5px;
  height: auto;
}

.featureCoordinatesLine{
  position: relative;
  float: right;
  width: auto;
  margin-left: 5px;
  height: 100%;
  font-size: 12px;
  line-height: 25px;
  text-align: right;
}

.featureCoordinatesPoint{
  position: relative;
  float: right;
  width: auto;
  margin-left: 5px;
  height: 100%;
  font-size: 12px;
  line-height: 50px;
  text-align: right;
}

.featureCarrot{
  position: relative;
  float: right;
  width: auto;
  margin-left: 5px;
  height: 100%;
  font-size: 12px;
  line-height: 25px;
  z-index: 100;
}

.featureCarrot img{
  position: relative;
  height: 20px;
  margin-top: 15px;
  width: auto;
  float: right;
  cursor: pointer;
  z-index: 100;
}

.featureAttributes{
  position: relative;
  float: left;
  width: 100%;
  height: auto;
  min-height: 50px;
  height: auto;
  background-color: #ffffff;
  box-sizing: border-box;
  padding: 5px;
  color: #000000;
  display: none;
  font-size: 12px;
  line-height: 24px;
}

.attributeWrapper{
  position: relative;
  width: 100%;
  float: left;
  height: auto;
  margin-bottom: 5px;
  line-height: 30px;
}

.attributeFieldWrapper{
  position: relative;
  float: left;
  width: 200px;
}

.attributeValueWrapper{
  position: relative;
  float: left;
  width: auto;
}

.updatedAttributesWrapper{
  position: relative;
  float: left;
  width: 50%;
  height: auto;
}

.originalAttributesWrapper{
  position: relative;
  float: left;
  width: 50%;
  height: auto;
}
.addedFeaturesWrapper{
  position: relative;
  float: left;
  width: 100%;
  height: auto;
  margin-top: 30px;
  color: #c62828;
  text-align: left;
  font-size: 22px;
  margin-bottom: 20px;
}

.editSessionHeader{
  position: relative;
  width: 100%;
  height: 60px;
  border-bottom-style: solid;
  border-width: 2px;
  border-color: #acacac;
  font-size: 24px;
  margin-bottom: 10px;
}

.editSessionTitle{
  position: relative;
  width: 100%;
  height: 60px;
  border-bottom-style: solid;
  border-width: 2px;
  border-color: rgb(224, 228, 234);
  font-size: 20px;
  line-height: 60px;
  text-align: left;
}

.editSessionClose{
  position: relative;
  width: auto;
  height: 60px;
  font-size: 20px;
  line-height: 60px;
  float: right;
  cursor: pointer;
  z-index: 10;
  text-decoration: none;
  color: #000;
}

.editSessionClose a{
  text-decoration: none;
  color: #000;
}

.editSessionResponses{
  position: relative;
  float: left;
  width: 100%;
  height: auto;
  text-align: left;
  margin-bottom: 10px;
}

.editSessionResponses button{
  position: relative;
  float: left;
  margin-right: 10px;
  height: 50px;
}
.map{
  width: 100% !important;
  height: 600px;
  top: 0px !important;
  position: relative !important;
  overflow: hidden;
  border-style: solid;
  border-width: 1px;
  border-color: #cccccc;
  box-sizing: border-box;
}

.editSessionMapWrapper{
	width: 100% !important;
  height: auto;
  position: relative;
  float: left;
  box-sizing: border-box;
  margin-bottom: 25px;
}

.attributeTitle{
  position: relative;
  float:left;
  width: 100%;
  height: auto;
  background-color: #fff;
}
.featureWrapper{
  position: relative;
  float: left;
  width: 100%;
  background-color: #ffffff;
  height: auto;
  min-height: 60px;
  margin-top: 10px;
  box-sizing: border-box;
  padding: 5px;
  text-align: left;
  line-height: 50px;
  text-decoration: none;
  color: #000;
  font-size: 18px;
}

.featureWrapperChecked{
  position: relative;
  float: left;
  width: 100%;
  margin-top: 10px;
  height: auto;
  min-height: 60px;
  line-height: 50px;
  text-align: left;
  background-color: #ffffff;
  box-sizing: border-box;
  padding: 5px;
  color: #000000;
  font-size: 18px;
  outline: none;
  box-shadow: inset 0px 0px 5px #cc3300;
}

.featureSelected{
  position: relative;
  float: left;
  width: 25px;
  margin-right: 15px;
  margin-left: 15px;
  height: 50px;
}

.featureStatus{
  position: relative;
  float: left;
  width: auto;
  bottom: 0px;
}

.featureId{
  position: relative;
  float: left;
  width: auto;
  margin-right: 5px;
  height: auto;
}

.featureCoordinatesLine{
  position: relative;
  float: right;
  width: auto;
  margin-left: 5px;
  height: 100%;
  font-size: 12px;
  line-height: 25px;
  text-align: right;
}

.featureCoordinatesPoint{
  position: relative;
  float: right;
  width: auto;
  margin-left: 5px;
  height: 100%;
  font-size: 12px;
  line-height: 50px;
  text-align: right;
}

.featureCarrot{
  position: relative;
  float: right;
  width: auto;
  margin-left: 5px;
  height: 100%;
  font-size: 12px;
  line-height: 25px;
  z-index: 100;
}

.featureCarrot img{
  position: relative;
  height: 20px;
  margin-top: 15px;
  width: auto;
  float: right;
  cursor: pointer;
  z-index: 100;
}

.featureAttributes{
  position: relative;
  float: left;
  width: 100%;
  height: auto;
  min-height: 50px;
  height: auto;
  background-color: #ffffff;
  box-sizing: border-box;
  padding: 5px;
  color: #000000;
  display: none;
  font-size: 12px;
  line-height: 24px;
}

.attributeWrapper{
  position: relative;
  width: 100%;
  float: left;
  height: auto;
  margin-bottom: 5px;
}

.attributeFieldWrapper{
  position: relative;
  float: left;
  width: 200px;
}

.attributeValueWrapper{
  position: relative;
  float: left;
  width: auto;
}
.addedFeaturesWrapper{
  position: relative;
  float: left;
  width: 100%;
  height: auto;
  margin-top: 30px;
  color: #c62828;
  text-align: left;
  font-size: 22px;
  margin-bottom: 20px;
}

.editSessionHeader{
  position: relative;
  width: 100%;
  height: 60px;
  border-bottom-style: solid;
  border-width: 2px;
  border-color: #acacac;
  font-size: 24px;
  margin-bottom: 10px;
}

.editSessionTitle{
  position: relative;
  width: 100%;
  height: 60px;
  border-bottom-style: solid;
  border-width: 2px;
  border-color: rgb(224, 228, 234);
  font-size: 20px;
  line-height: 60px;
  text-align: left;
}

.editSessionClose{
  position: relative;
  width: auto;
  height: 60px;
  font-size: 20px;
  line-height: 60px;
  float: right;
  cursor: pointer;
  z-index: 10;
  text-decoration: none;
  color: #000;
}

.editSessionClose a{
  text-decoration: none;
  color: #000;
}

.editSessionResponses{
  position: relative;
  float: left;
  width: 100%;
  height: auto;
  text-align: left;
  margin-bottom: 10px;
}

.editSessionResponses button{
  position: relative;
  float: left;
  margin-right: 10px;
  height: 50px;
}

.noAssets {
  position: relative;
  float: left;
  width: 100%;
  /* margin-left: 10%; */
  height: auto;
  color: #000;
  box-sizing: border-box;
  padding: 15px;
  margin-top: 20px;
  box-shadow: 0 0 10px rgba(0,0,0,0.6);
  -moz-box-shadow: 0 0 10px rgba(0,0,0,0.6);
  -webkit-box-shadow: 0 0 10px rgba(0,0,0,0.6);
  -o-box-shadow: 0 0 10px rgba(0,0,0,0.6);
  border-radius: 15px;
  background-color: #f9f8f8;
  border-color: #868686;
}


a{
  text-decoration: none;
}
.map{
  width: 100% !important;
  height: 600px;
  top: 0px !important;
  position: relative !important;
  overflow: hidden;
  border-style: solid;
  border-width: 1px;
  border-color: #cccccc;
  box-sizing: border-box;
}

.editSessionMapWrapper{
	width: 100% !important;
  height: auto;
  position: relative;
  float: left;
  box-sizing: border-box;
  margin-bottom: 25px;
}

.attributeTitle{
  position: relative;
  float:left;
  width: 100%;
  height: auto;
  background-color: #fff;
}
.addedFeaturesWrapper{
  position: relative;
  float: left;
  width: 100%;
  height: auto;
  margin-top: 30px;
  color: #c62828;
  text-align: left;
  font-size: 22px;
  margin-bottom: 20px;
}

.editSessionHeader{
  position: relative;
  width: 100%;
  height: 60px;
  border-bottom-style: solid;
  border-width: 2px;
  border-color: #acacac;
  font-size: 24px;
  margin-bottom: 10px;
}

.editSessionTitle{
  position: relative;
  width: 100%;
  height: 60px;
  border-bottom-style: solid;
  border-width: 2px;
  border-color: rgb(224, 228, 234);
  font-size: 20px;
  line-height: 60px;
  text-align: left;
}

.editSessionClose{
  position: relative;
  width: auto;
  height: 60px;
  font-size: 20px;
  line-height: 60px;
  float: right;
  cursor: pointer;
  z-index: 10;
  text-decoration: none;
  color: #000;
}

.editSessionClose a{
  text-decoration: none;
  color: #000;
}

.editSessionResponses{
  position: relative;
  float: left;
  width: 100%;
  height: auto;
  text-align: left;
  margin-bottom: 10px;
}

.editSessionResponses button{
  position: relative;
  float: left;
  margin-right: 10px;
  height: 50px;
}
.pageDescription{
  position: relative;
  float: left;
  width: 80%;
  height: auto;
  margin-left: 10%;
  font-size: 15px;
  text-align: left;
  margin-top: 15px;
  margin-bottom: 30px;
}

.pageDescriptionDemo{
  position: relative;
  float: left;
  width: 80%;
  height: auto;
  margin-left: 10%;
  font-size: 15px;
  text-align: left;
  margin-top: 15px;
  margin-bottom: 30px;
}

.howitworksExplanation{
  position: relative;
  width: 100%;
  height: auto;
}

.howitworksIndustryWrapper{
  position: relative;
  float: left;
  width: 100%;
  height: auto;
  margin-bottom: 50px;
}

.industryWrapper{
  position: relative;
  float: left;
  width: 31.3%;
  height: 250px;
  text-align: left;
  margin-left: 1%;
  margin-right: 1%;
  text-align: center;
  background-color: #fff;
  box-shadow: 0 5px 15px 0 rgba(0,0,0,.08), 0 1px 3px 0 rgba(0,0,0,.12);
  margin-bottom: 8px;
  color: #4c535d;
  border-radius: 4px;
  box-sizing: border-box;
  padding: 10px;
  cursor: pointer;
}

.industryWrapperTitle{
  position: relative;
  width: 100%;
  height: 50px;
  text-align: left;
  font-size: 28px;
  font-weight: bold;
}

.industryWrapperImg{
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 100px;
  height: auto;
}

.industryWrapperImg img{
  width: 100%;
  height: auto;
}

.industryDetailWrapper{
  position: relative;
  float: left;
  width: 100%;
  height: auto;
  background-color: rgb(255, 255, 255);
  box-sizing: border-box;
  padding: 20px;
  font-family:Lomino, LominoSubset, "open-sans", sans-serif; 
  margin-bottom: 50px;
}

.industryDetailTitle{
  position: relative;
  float: left;
  width: 100%;
  text-align: center;
  font-size: 60px;
  font-weight: 600;
}

.industryDetailDescription{
  position: relative;
  float: left;
  width: 100%;
  text-align: center;
  font-size: 16px;
  font-weight: 300;
  margin-bottom: 30px;
}

.industryDetailImage{
  position: relative;
  float: left;
  width: 100%;
  height: auto;
}

.industryDetailImage img{
  position: relative;
  float: left;
  width: 100%;
  height: auto;
}

.industryWrapperNextImg{
  position: absolute;
  bottom: 0px;
  right: 0px;
  width: 100px;
  height: auto;
}

.industryWrapperNextImg img{
  width: 100%;
  height: auto;
}

.howItWorksVideo{
  width: 100%;
  height: auto;
  position: relative;
  overflow: hidden;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 5px 15px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px;
  margin-bottom: 8px;
  color: rgb(76, 83, 93);
  border-radius: 4px;
  box-sizing: border-box;
  padding: 15px;
  background-color: white;
}

.featureRow{
  position: relative;
  float: left;
  width: 100%;
  height: auto;
  font-size: 15px;
  margin-bottom: 35px;
}

.keyFeature{
  position: relative;
  float: left;
  width: 33%;
  height: auto;
  text-align: left;
}

.featureDesc{
  position: relative;
  float: left;
  width: 66%;
  height: 100%;
}

.featureTitle{
  position: relative;
  float: left;
  width: 100%;
  height: auto;
  font-size: 18px;
  margin-bottom: 15px;
}

@media screen and (max-width : 1050px) {
  .keyFeature{
    width: 100%;
    text-align: center;
    margin-bottom: 10px;
  }

  .pageDescription{
    text-align: center;
    width: 90%;
    margin-left: 5%;
  }

  .pageDescriptionDemo{
    text-align: left;
    width: 90%;
    margin-left: 5%;
  }

  .featureRow{
    text-align: center;
    margin-bottom: 0px;
  }

  .featureDesc{
    width: 100%;
  }

  .industryWrapper{
    width: 100%;
    margin-left: 0px;
    margin-right: 0px;
    height: 150px;
  }

  .industryWrapperTitle{
    font-size: 20px;
  }

  .industryDetailTitle{
    font-size: 35px;
  }
}
.map{
  width: 100% !important;
  height: 600px;
  top: 0px !important;
  position: relative !important;
  overflow: hidden;
  border-style: solid;
  border-width: 1px;
  border-color: #cccccc;
  box-sizing: border-box;
}
.communityMapWrapper{
	width: 100% !important;
    height: auto;
    position: relative;
    box-sizing: border-box;
    margin-bottom: 25px;
}

.teamWrapper{
  position: relative;
  float: left;
  width: 100%;
  height: auto;
  margin-bottom: 20px;
}

.teamMemberOdd{
  position: relative;
  float: left;
  width: 100%;
  height: auto;
  background-color: #ebebeb;
  box-sizing: border-box;
  padding: 10px;
  margin-bottom: 10px;
}

.teamMemberImage{
  position: relative;
  float: left;
  width: 20%;
  height: auto;
  box-sizing: border-box;
  padding: 5px;
}

.teamMemberImage img{
  position: relative;
  float: left;
  width: 100%;
  height: auto;
}

.teamMemberDesc{
  position: relative;
  float: left;
  width: 70%;
  height: auto;
  box-sizing: border-box;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 15px;
}

@media screen and (max-width : 1050px) {
  .teamMemberImage{
    width: 100%;
    height: auto;
  }

  .teamWrapper{
    text-align: center;
  }

  .teamMemberDesc{
    width: 100%;
  }

  .pageDescription ul{
    text-align: left;
  }
}
/*.contactForm{
  position: relative;
  display: inline-block;
  width: 100%;
  height: auto;
  border-style: solid;
  border-width: 1px;
  border-color: #cbced3;
  margin: auto;
  text-align: left;
  margin-top: 15px;
  box-sizing: border-box;
  padding: 10px;
  color: #000000;
  background-color: #f2f2f2;
  margin-bottom: 10px;
}*/

.contactForm{
  position: relative;
  text-align: left;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 5px 15px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px;
  margin-bottom: 8px;
  width: -webkit-min-content;
  width: -moz-min-content;
  width: min-content;
  min-width: 100%;
  color: rgb(76, 83, 93);
  border-radius: 4px;
  box-sizing: border-box;
  padding: 15px;
  height: auto;
}

.contactInput{
  position: relative;
  width: 100%;
  box-sizing: border-box;
  height: 35px;
  padding: 5px;
}

.contactArea{
  position: relative;
  width: 100%;
  max-width: 100%;
  min-width: 100%;
  min-height: 150px;
  height: auto;
  box-sizing: border-box;
  margin-bottom: 15px;
}

.recaptchaWrapper{
  position: relative;
  width: 100%;
  height: auto;
  margin-bottom: 10px;
}

.submitContactBtnWrapper{
  position: relative;
  width: 100%;
  height: auto;
}
/*.newsPreviewWrapper{
  position: relative;
  float: left;
  width: 31.3%;
  height: 350px;
  background-color: #EBEBEB;
  margin-left: 1%;
  margin-right: 1%;
  text-align: center;
  box-sizing: border-box;
  padding: 5px;
  cursor: pointer;
  margin-bottom: 20px;
}*/

.newsPreviewWrapper{
  position: relative;
  float: left;
  width: 31.3%;
  height: 350px;
  text-align: left;
  margin-left: 1%;
  margin-right: 1%;
  text-align: center;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 5px 15px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px;
  margin-bottom: 8px;
  color: rgb(76, 83, 93);
  border-radius: 4px;
  box-sizing: border-box;
  padding: 15px;
}

.newsPreviewTitle{
  position: relative;
  float: left;
  width: 100%;
  height: auto;
  font-size: 18px;
  font-weight: bold;
  border-bottom-style: solid;
  border-bottom-color: #d6d6d6;
  border-bottom-width: 1px;
  min-height: 75px;
  box-sizing: border-box;
  padding: 15px;
}

.newsPreviewTitle a{
  text-decoration: none;
  color: #000;
}

.newsPreviewImage{
  position: relative;
  display: block;
  /* float: left; */
  max-width: 80%;
  width: auto;
  max-height: 220px;
  height: auto;
  margin-left: 10%;
  /* margin-top: 15px; */
  text-align: center;
  vertical-align: middle;
}

.newsPreviewImage img{
  max-width: 100%;
  width: auto;
  height: auto;
  max-height: 220px;
  display: inline-block;
  vertical-align: middle;
  margin-top: 20px;
}

@media screen and (max-width : 1050px) {
  .newsPreviewWrapper{
    width: 100%;
    margin-left: 0px;
  }

  .newsPreviewImage{
    width: 100%;
    max-width: 100%;
    margin-left: 0px;
  }
}
.pageDescription a{
  text-decoration: none;
  color: #000;
}

.pageDescription a:hover{
  text-decoration: none;
  color: #000;
}

blockquote {
    margin: 0 0 1rem;
    overflow: hidden;
    padding-right: 1.5em;
    padding-left: 1.5em;
    margin-left: 20px;
    margin-right: 0;
    font-style: italic;
    border-left: 5px solid #ccc;
}
.pageDescription a{
  	text-decoration: none;
  	color: #000;
}

.pageDescription a:hover{
 	text-decoration: none;
  	color: #000;
}

.newsArticleWrapper{
	position: relative;
	float: left;
	width: 80%;
	margin-left: 10%;
	box-sizing: border-box;
	padding: 10px;
	text-align: left;
}

.newsImageFull{
	position: relative;
    width: 80%;
    margin-left: 10%;
    height: auto;
}
.pageDescription a{
  	text-decoration: none;
  	color: #000;
}

.pageDescription a:hover{
 	text-decoration: none;
  	color: #000;
}

.newsArticleWrapper{
	position: relative;
	float: left;
	width: 80%;
	margin-left: 10%;
	box-sizing: border-box;
	padding: 10px;
	text-align: left;
}

.newsImageFull{
	position: relative;
    width: 80%;
    margin-left: 10%;
    height: auto;
}
.subscriptionGroupTitle{
    border-bottom: solid;
    border-width: 2px;
    border-color: #e0e4ea;
    margin-bottom: 10px;
}

.subscriptionOptions{
  position: relative;
  width: 100%;
  height: auto;
  display: inline-block;
}

.subscriptionGroup{
  position: relative;
  width: 100%;
  height: auto;
  float: left;
  margin-bottom: 30px;
}

.createAccount button{
  background-color: #c62828;
  border: none;
  color: #ffffff;
  height: 50px;
  position: relative;
  max-width: 400px;
  width: 100%;
  margin-bottom: 20px;
  font-size: 20px;
  cursor: pointer;
}

.createAccount{
  text-align: left;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 5px 15px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px;
  margin-bottom: 8px;
  width: 100%;
  min-width: 100%;
  color: rgb(76, 83, 93);
  border-radius: 4px;
  box-sizing: border-box;
  padding: 15px;
  float: left;
}

.accountTypeInfo img{
  width: 20px;
  height: auto;
}

.accountTypeInfo{
  width: 25px;
  height: auto;
  position: relative;
  display: inline-block;
}

.infoPopup{
  position: absolute;
  left: 20px;
  top: 0px;
  display: none;
  width: 500px;
  height: auto;
  background-color: #2b2b2b;
  color: #fff;
  box-sizing: border-box;
  padding: 10px;
}

.accountTypeInfo:hover .infoPopup{
  display: block;
}

.stripeLogo{
  width: 150px;
  height: auto;
}

.mailingWrapper{
  position: fixed;
  text-align: left;
  z-index: 1001;
  left: 50%;
  top: 50%;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 5px 15px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px;
  color: rgb(76, 83, 93);
  border-radius: 4px;
  box-sizing: border-box;
  padding: 15px;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  /*min-height: 60%;*/
  height: 80%;
  width: 80%;
  border-radius: 25px;
  overflow: hidden;
  overflow-y: scroll;
}

.mailingBackground{
  position: fixed;
  z-index: 1000;
  height: 100%;
  width: 100%;
  background: rgba( 0, 0, 0, .6 )
}

.mailingClose{
  position: absolute;
  right: 15px;
  top: 15px;
  width: 20px;
  height: 20px;
  cursor: pointer;
  z-index: 1002;
}

.mailingHeader{
  position: relative;
  float: left;
  width: 100%;
  height: auto;
  font-size: 28px;
  font-weight: bold;
}

.mailingClose img{
  position: relative;
  width: 100%;
  height: 100%;
}

.mailingInfo{
  position: relative;
  float: left;
  width: 100%;
  height: auto;
  margin-top: 30px;
  overflow: hidden;
}

.mailingApprove{
  position: relative;
  float: left;
  width: 100%;
  height: auto;
}

.mailingApprove button{
  background-color: #c62828;
  border: none;
  color: #ffffff;
  min-height: 50px;
  height: auto;
  position: relative;
  max-width: 600px;
  width: 100%;
  margin-bottom: 20px;
  font-size: 20px;
  cursor: pointer;
}

@media screen and (max-width : 1050px) {
  .mailingWrapper{
    width: 95%;
    height: 95%;
  }
}
/*.recaptchaContainer{
  position: relative;
  transform: scale(calc(0.8));
  transform-origin: 0 0;
}*/
.partnersWrapper{
	position: relative;
    float: left;
    width: 80%;
    height: auto;
    margin-left: 10%;
    font-size: 15px;
    text-align: left;
    margin-top: 15px;
    margin-bottom: 30px;
}

.partnersWrapper img{
	position: relative;
	float: left;
	width: auto;
	height: 60px;
	margin-left: 10px;
	margin-right: 10px;
}

.container{
    display: flex;
    flex-wrap: wrap;
}
.card {
    flex: 1 0 auto;
    box-sizing: border-box;
    margin: 1rem .25em;
    width: 190px;
    height: 330px;
    box-shadow: rgba(0, 0, 0, 0.08) 0px 5px 15px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px;
    border-radius: 4px;
    color: rgb(76, 83, 93);
    /*
position: relative;
  float: left;
  width: 300px;
  height: 280px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 5px 15px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px;
  margin-top: 15px;
  margin-bottom: 8px;
  color: rgb(76, 83, 93);
  border-radius: 4px;
  box-sizing: border-box;
  padding: 0px;*/
}

.card-body {
    padding: 10px;
}

.card-title{
    position: relative;
    float: left;
    width: 100%;
    font-size: 18px;
    font-weight: bold;
    padding-bottom: 10px;
    color: rgb(76, 83, 93);
    border-bottom-style: solid;
    border-bottom-color: #d6d6d6;
    border-bottom-width: 1px;
}

.card-text{
    position: relative;
    float: left;
    width: 100%;
    height: auto;
    font-size: 16px;
    min-height: 75px;
    box-sizing: border-box;
    padding: 0px;
    color: rgb(76, 83, 93);
}

.card-img{
    max-width: 100%;
    width: auto;
    height: 80px;
    margin: 5px;
   
}


.resetPasswordForm {
    position: relative;
    float: left;
    height: auto;
    width: 60%;
    margin-left: 20%;
}

.resetPasswordForm input {
    position: relative;
    width: 100%;
    height: 30px;
    margin-bottom: 20px;
    font-size: 20px;
}

.resetPasswordFormLabel{
	position: relative;
	width: 100%;
	float: left;
	height: auto;
	text-align: left;
}

.resetPasswordForm button {
    background-color: #c62828;
    border: none;
    color: #ffffff;
    height: 50px;
    position: relative;
    width: 100%;
    margin-bottom: 20px;
    font-size: 20px;
    cursor: pointer;
}

.chartWrapper{
  position: relative;
  width: 100%;
  height: 350px;
  float: left;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 5px 15px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px;
  margin-bottom: 8px;
  color: rgb(76, 83, 93);
  border-radius: 4px;
  box-sizing: border-box;
  padding: 15px;
  background-color: white;
  margin-top: 50px;
}

.mainModuleWrapper{
  position: relative;
  float: left;
  width: 100%;
  height: auto;
}
.adminModuleWrapper{
	position: relative;
	float: left;
	width: 22%;
	margin-left: 1.5%;
	margin-right: 1.5%;
	margin-top: 20px;
	height: 150px;
	box-shadow: rgba(0, 0, 0, 0.08) 0px 5px 15px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px;
	margin-bottom: 8px;
	color: rgb(76, 83, 93);
	border-radius: 4px;
	box-sizing: border-box;
	padding: 15px;
	background-color: white;
	cursor: pointer;
}

.adminModuleWrapperSelected{
	position: relative;
	float: left;
	width: 22%;
	margin-left: 1.5%;
	margin-right: 1.5%;
	margin-top: 20px;
	height: 150px;
	background-color: #007bff;
	box-shadow: rgba(0, 0, 0, 0.08) 0px 5px 15px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px;
	margin-bottom: 8px;
	color: rgb(76, 83, 93);
	border-radius: 4px;
	box-sizing: border-box;
	padding: 15px;
	cursor: pointer;
}

.adminModuleWrapper{
	text-decoration: none;
	color: #000;
}

.adminModuleLarge{
	position: relative;
	float: left;
	width: 100%;
	text-align: center;
	height: 80%;
	/*line-height: 200px;*/
	font-size: 80px;
	font-weight: bold;
}

.adminModuleLarge img{
	position: relative;
	max-width: 90%;
	margin: auto;
	height: 100%;
}

.adminModuleFooter{
	position: relative;
	float: left;
	height: 20%;
	width: 100%;
	text-align: center;
	font-size: 20px;
}

.adminPageTitle {
    position: relative;
    float: left;
    width: 100%;
    color: #c62828;
    font-size: 35px;
    text-align: left;
    margin-top: 15px;
    border-bottom-style: solid;
    border-color: #acacac;
    border-width: 2px;
}

.adminPageDescription {
    position: relative;
    float: left;
    width: 100%;
    height: auto;
    /* margin-left: 10%; */
    font-size: 15px;
    text-align: left;
    margin-top: 15px;
    margin-bottom: 30px;
}
.accountUpdate {
  position: relative;
  float: right;
  width: auto;
  bottom: 0px;
  cursor: pointer;
  text-align: right;
}

.accountUpdate button{
  margin-left: 10px;
}
.adminPageTitle {
    position: relative;
    float: left;
    width: 100%;
    color: #c62828;
    font-size: 35px;
    text-align: left;
    margin-top: 15px;
    border-bottom-style: solid;
    border-color: #acacac;
    border-width: 2px;
}

.adminPageDescription {
    position: relative;
    float: left;
    width: 100%;
    height: auto;
    /* margin-left: 10%; */
    font-size: 15px;
    text-align: left;
    margin-top: 15px;
    margin-bottom: 30px;
}
.moduleSelectionWrapper{
	position: relative;
	float: left;
	width: 100%;
	height: auto;
	margin-top: 50px;
}


.moduleMenu{
	position: relative;
	float: left;
	width: 97%;
	height: auto;
	min-height: 100px;
	background-color: #fff;
	margin-top: 30px;
	line-height: 100px
}

.moduleMenu button{
	display: relative;
	float: left;
	margin-top: 30px;
	margin-right: 10px;
}
.moduleSelectionWrapper{
	position: relative;
	float: left;
	width: 100%;
	height: auto;
	margin-top: 50px;
}


.moduleSelectionWrapper{
	position: relative;
	float: left;
	width: 100%;
	height: auto;
	margin-top: 50px;
}
/* Style the form - display items horizontally */
.form-inline {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    width: 33%
  }
  
  /* Add some margins for each label */
  .form-inline label {
    margin: 5px 10px 5px 0;
  }
  
  /* Style the input fields */
  .form-inline input {
    width: 100%;
    padding: 12px 20px;
    margin: 8px 0;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 0;
    box-sizing: border-box;
  }
  .form-inline input[type=submit] {
    width: 100%;
    background-color: #4CAF50;
    color: white;
    padding: 14px 20px;
    margin: 8px 0;
    border: none;
    border-radius: 0;
    cursor: pointer;
  }
  
  /* Style the submit button */
  .form-inline button {
    padding: 10px 20px;
    background-color: #c62828;
    border: none;
    color: white;
  }
  
  .form-inline button:hover {
    background-color: #c62828;
  }
  
  /* Add responsiveness - display the form controls vertically instead of horizontally on screens that are less than 800px wide */
  @media (max-width: 800px) {
    .form-inline input {
      margin: 10px 0;
    }
  
    .form-inline {
      flex-direction: column;
      align-items: stretch;
    }
  }

  /* Style the submit button */
  .cancel-btn {
    width: 100%;
    background-color:#c62828;
    color: white;
    padding: 14px 20px;
    margin: 8px 0;
    border: none;
    border-radius: 0;
    cursor: pointer;
  }
/* Style the container */
.container {
    border-radius: 5px;
    background-color: #f2f2f2;
    padding: 0;
  }
  
  /* Floating column for labels: 25% width */
  .col-25 {
    float: right;
    width: 25%;
    margin-top: 30px;
    border-radius: 5px;
    background-color: #f2f2f2;
  }
  
  /* Floating column for inputs: 75% width */
  .col-75 {
    float: left;
    width: 75%;
    margin-top: 30px;
    padding-right: 5px;
    overflow:auto;
    border-radius: 5px;
    background-color: #f2f2f2;
  }


  /* Floating column for labels: 25% width */
  .col-100 {
    float: right;
    width: 100%;
    margin-top: 30px;
    border-radius: 5px;
    background-color: #f2f2f2;
  }

  /* Style inputs */
  input, select {
    width: 100%;
    padding: 12px 20px;
    margin: 8px 0;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 0;
    box-sizing: border-box;
  }
  
  /* Style the submit button */
  input[type=submit] {
    width: 100%;
    background-color: #4CAF50;
    color: white;
    padding: 14px 20px;
    margin: 8px 0;
    border: none;
    border-radius: 0;
    cursor: pointer;
  }
  
  /* Add a background color to the submit button on mouse-over */
  input[type=submit]:hover {
    background-color: #c62828;
  }

  /* Style the submit button */
  .cancel-btn {
    width: 100%;
    background-color:#c62828;
    color: white;
    padding: 14px 20px;
    margin: 8px 0;
    border: none;
    border-radius: 0;
    cursor: pointer;
    
  }


  .panel-header {
    border: #ffffff;
    padding: 15px;
    margin: 10px 0 0 0;
    background-color: #fff;
  }
  
  .panel-header button {
    border: none;
    background: transparent;
  }
  .panel-header button:focus {
    outline: 0px auto -webkit-focus-ring-color;
  }

  .feature-layer{
    background-color: white;
  }


  
  

 /* Floating column for labels: 25% width */
 .col-25 {
    float: right;
    width: 25%;
    margin-top: 30px;
    border-radius: 5px;
    background-color: #f2f2f2;
  }
  
  /* Floating column for inputs: 75% width */
  .col-75 {
    float: left;
    width: 75%;
    margin-top: 30px;
    padding-right: 5px;
    overflow:auto;
    border-radius: 5px;
    background-color: #f2f2f2;
  }

  /* Floating column for labels: 25% width */
  .col-100 {
    float: right;
    width: 100%;
    margin-top: 30px;
    border-radius: 5px;
    background-color: #f2f2f2;
  }
  /* Style inputs */
  input, select {
    width: 100%;
    padding: 12px 20px;
    margin: 8px 0;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 0;
    box-sizing: border-box;
  }
  
  /* Style the submit button */
  input[type=submit] {
    width: 100%;
    background-color: #4CAF50;
    color: white;
    padding: 14px 20px;
    margin: 8px 0;
    border: none;
    border-radius: 0;
    cursor: pointer;
  }
  
  /* Add a background color to the submit button on mouse-over */
  input[type=submit]:hover {
    background-color: #c62828;
  }

  /* Style the submit button */
  .cancel-btn {
    width: 100%;
    background-color:#c62828;
    color: white;
    padding: 14px 20px;
    margin: 8px 0;
    border: none;
    border-radius: 0;
    cursor: pointer;
  }
.videoPreviewWrapper{
  position: relative;
  float: left;
  width: 31.3%;
  height: 350px;
  text-align: left;
  margin-left: 1%;
  margin-right: 1%;
  text-align: center;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 5px 15px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px;
  margin-bottom: 8px;
  color: rgb(76, 83, 93);
  border-radius: 4px;
  box-sizing: border-box;
  margin-top: 20px;
  cursor: pointer;
}

.videoPreviewTitle{
  position: relative;
  float: left;
  width: 100%;
  height: 20%;
  font-size: 18px;
  font-weight: bold;
  /* border-bottom-style: solid; */
  /* border-bottom-color: #d6d6d6; */
  /* border-bottom-width: 1px; */
  /* min-height: 75px; */
  box-sizing: border-box;
  padding: 15px;
  text-decoration: none;
  color: #000;
  line-height: 45px;
}

.videoPreviewImage{
  position: relative;
  /* display: block; */
  float: left;
  width: 100%;
  /* max-height: 220px; */
  height: 80%;
  /* margin-top: 2%; */
  text-align: center;
  border-radius: 4px;
}

.videoThumb{
  position: relative;
  display: block;
  /* float: left; */
  height: 100%;
  width: 100%;
}

.videoBackground{
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0,0,0,0.5);
  z-index: 50;
}

.expandedWrapper{
  position: fixed;
  width: 60%;
  height: 70%;
  top: 15%;
  left: 20%;
  text-align: center;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 5px 15px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px;
  color: rgb(76, 83, 93);
  border-radius: 4px;
  box-sizing: border-box;
  z-index: 100;
}

.expandedClose{
  position: absolute;
  top: 5px;
  right: 5px;
  width: 25px;
  height: 25px;
}

.expandedClose img{
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 10;
}

.expandedVideoWrapper{
  position: relative;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 30px;
}

.expandedVideoWrapper>iframe{
  position: relative;
  width: 100%;
  height: 100%;
}

@media screen and (max-width : 1050px) {
  .videoPreviewWrapper{
    width: 100%;
    margin-left: 0px;
  }

  .videoPreviewImage{
    width: 100%;
    max-width: 100%;
    margin-left: 0px;
  }
}
.videoViewWrapper{
	position: relative;
	float: left;
	width: 100%;
	height: auto;
	margin-top: 20px;
}


/*.newsPreviewWrapper{
  position: relative;
  float: left;
  width: 31.3%;
  height: 350px;
  background-color: #EBEBEB;
  margin-left: 1%;
  margin-right: 1%;
  text-align: center;
  box-sizing: border-box;
  padding: 5px;
  cursor: pointer;
  margin-bottom: 20px;
}*/

.bimPreviewWrapper {
  position: relative;
  float: left;
  width: 31.3%;
  height: 350px;
  text-align: left;
  margin-left: 1%;
  margin-right: 1%;
  text-align: center;
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 5px 15px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px;
  margin-bottom: 8px;
  color: rgb(76, 83, 93);
  border-radius: 4px;
  box-sizing: border-box;
  margin-top: 20px;
  cursor: pointer;
}

.bimPreviewTitle{
  position: relative;
  float: left;
  width: 100%;
  height: 20%;
  font-size: 18px;
  font-weight: bold;
  /* border-bottom-style: solid; */
  /* border-bottom-color: #d6d6d6; */
  /* border-bottom-width: 1px; */
  /* min-height: 75px; */
  box-sizing: border-box;
  padding: 15px;
  text-decoration: none;
  color: #000;
  line-height: 45px;
}

.newsPreviewTitle a{
  text-decoration: none;
  color: #000;
}

.bimPreviewImage {
  position: relative;
  /* display: block; */
  float: left;
  width: 100%;
  /* max-height: 220px; */
  height: 80%;
  /* margin-top: 2%; */
  text-align: center;
  border-radius: 4px;
  background-color: #eaeaea;
}

.bimPreviewImage img{
  position: relative;
  /*display: block;*/
  /* float: left; */
  height: 100%;
  width: auto;
  max-width: 100%;
}

@media screen and (max-width : 1050px) {
  .newsPreviewWrapper{
    width: 100%;
    margin-left: 0px;
  }

  .newsPreviewImage{
    width: 100%;
    max-width: 100%;
    margin-left: 0px;
  }
}
a{
  text-decoration: none;
}

.noProjects{
  position: relative;
  float: left;
  width: 80%;
  margin-left: 10%;
  height: auto;
  color: #000;
  box-sizing: border-box;
  padding: 15px;
  margin-top: 20px;
  box-shadow:  0 0 10px  rgba(0,0,0,0.6);
  -moz-box-shadow: 0 0 10px  rgba(0,0,0,0.6);
  -webkit-box-shadow: 0 0 10px  rgba(0,0,0,0.6);
  -o-box-shadow: 0 0 10px  rgba(0,0,0,0.6);
  border-radius: 15px;
  background-color: #fff3cd;
  border-color: #ffeeba;
}

.bimProjectWrapper{
  position: relative;
  float: left;
  width: 100%;
  height: auto;
  margin-top: 20px;
}
.bimProjectViewWrapper{
  position: relative;
  float: left;
  width: 100%;
  height: auto;
  box-sizing: border-box;
  padding: 10px;
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 5px 15px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px;
  color: rgb(76, 83, 93);
  border-radius: 4px;
  margin-top: 20px;
}

.bimProjectThumb{
  position: relative;
  float: left;
  height: auto;
  width: 25%;
}

.bimProjectThumb img{
  position: relative;
  float: left;
  width: 100%;
  height: 100%;
}

.bimProjectInfo{
  position: relative;
  float: left;
  width: 73%;
  height: auto;
  margin-left: 2%;
}

.bimProjectInfo input{
  position: relative;
  float: left;
  width: 100%;
}

.bimProjectInfo textarea{
  min-height: 150px;
  font-size: 15px;
  line-height: 30px;
  display: inline-block;
  /* max-width: 90%; */
  min-width: 100%;
  font-family: Arial;
}

.modelPreviewWrapper{
  position: relative;
  float: left;
  width: 100%;
  height: auto;
  margin-top: 15px;
  margin-bottom: 15px;
}

.bimPreviewButtons{
  position: relative;
  float: right;
  width: 100%;
  height: auto;
  margin-top: 10px;
  text-align: right;
}

.bimPreviewButtons button{
  margin-right: 10px;
}
.pricingDetailWrapper{
  position: relative;
  float: left;
  width: 100%;
  height: auto;
  background-color: rgb(255, 255, 255);
  box-sizing: border-box;
  padding: 20px;
  font-family:Lomino, LominoSubset, "open-sans", sans-serif; 
  margin-bottom: 50px;
}

.pricingDetailTitle{
  position: relative;
  float: left;
  width: 100%;
  text-align: left;
  font-size: 60px;
  font-weight: 600;
}

.calculatorOperator{
  position: relative;
  float: left;
  width: 25px;
  text-align: center;
}

.pricingDetailDescription{
  position: relative;
  float: left;
  width: 100%;
  text-align: left;
  font-size: 16px;
  font-weight: 300;
  margin-bottom: 30px;
}

.pricingDetailImage{
  position: relative;
  float: left;
  width: 100%;
  height: auto;
}

.pricingDetailImage img{
  position: relative;
  width: 100%;
  height: auto;
  filter: blur(8px);
  -webkit-filter: blur(8px);
}

.industryWrapperNextImg{
  position: absolute;
  bottom: 0px;
  right: 0px;
  width: 100px;
  height: auto;
}

.pricingOptions{
  position: absolute;
  bottom: 50px;
  left: 0px;
  width: 100%;
  height: 300px;
  z-index: 100;
}

.pricingCalculatorUtilities{
  position: relative;
  float: left;
  width: 100%;
  height: auto;
  margin-top: 20px;
  margin-bottom: 20px;
}

.calculatorInput{
  position: relative;
  float: left;
  width: 100%;
  height: auto;
  margin-top: 20px;
}

.calculatorValueWrapper{
  position: relative;
  float: left;
  width: auto;
  height: auto;
  margin-right: 20px;
  margin-top: 20px;
}

.calculatorValueWrapper2{
  position: relative;
  float: left;
  width: auto;
  height: auto;
  margin-right: 20px;
  margin-top: 20px;
}

.calculatorValueWrapper input{
  position: relative;
  float: left;
  width: 250px;
  height: auto;
  margin-right: 20px;
}

.calculatorValueWrapper2 input{
  position: relative;
  float: left;
  width: 250px;
  height: auto;
  margin-right: 20px;
}

.pricingOption{
  position: relative;
  float: left;
  width: 31.3%;
  height: 300px;
  text-align: left;
  margin-left: 1%;
  margin-right: 1%;
  text-align: center;
  background-color: rgba(250,250,250,1);
  box-shadow: 0 5px 15px 0 rgba(0,0,0,.08), 0 1px 3px 0 rgba(0,0,0,.12);
  margin-bottom: 8px;
  color: #000;
  border-radius: 4px;
  box-sizing: border-box;
  padding: 10px;
}

.pricingOptionTitle{
  position: relative;
  float: left;
  width: 100%;
  height: 50px;
  line-height: 50px;
  font-size: 20px;
  font-weight: bold;
  border-bottom-style: solid;
  border-bottom-color: rgb(224, 228, 234);
  border-bottom-width: 1px;
}

.pricingOptionDescription{
  position: relative;
  float: left;
  width: 100%;
  height: auto;
  margin-top: 30%;
  line-height: 24px;
  font-size: 60px;
  font-weight: bold;
  overflow-wrap: break-word;
}

.industryWrapperNextImg img{
  width: 100%;
  height: auto;
}

.howItWorksVideo{
  width: 100%;
  height: auto;
  position: relative;
  overflow: hidden;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 5px 15px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px;
  margin-bottom: 8px;
  color: rgb(76, 83, 93);
  border-radius: 4px;
  box-sizing: border-box;
  padding: 15px;
  background-color: white;
}

.pricingOptionFeaturesWrapper{
  position: relative;
  float: left;
  width: 100%;
  height: auto;
}

.pricingOptionFeaturesTitle{
  position: relative;
  float: left;
  width: 100%;
  min-height: 75px;
  height: auto;
  font-family: "roboto","Open Sans",sans-serif;
  font-weight: bold;
  font-size: 40px;
  text-align: center;
  margin-top: 35px;
  border-bottom: solid;
  border-width: 2px;
  border-color: #e0e4ea;
}

.pricingOptionFeatureSingle{
  position: relative;
  float: left;
  width: 31.3%;
  height: 250px;
  text-align: left;
  margin-left: 1%;
  margin-right: 1%;
  text-align: left;
  color: #000;
  box-sizing: border-box;
  padding: 10px;
}

.pricingOptionFeatureImage{
  position: relative;
  float: left;
  width: 20%;
  height: auto;
}

.pricingOptionFeatureImage img{
  position: relative;
  float: left;
  width: 100%;
  height: auto;
}

.pricingOptionFeatureDescription{
  position: relative;
  float: left;
  width: 80%;
  height: auto;
  max-height: 200px;
  box-sizing: border-box;
  padding: 5px;
}

.pricingOptionDescriptionTitle{
  position: relative;
  float: left;
  width: 100%;
  height: auto;
  font-size: 20px;
  font-weight: bold;
  font-family: "Open Sans",sans-serif;
  margin-bottom: 20px;
  margin-top: 10px;
}


@media screen and (max-width : 1050px) {
  .pricingOption{
    width: 100%;
    margin-left: 0px;
    margin-right: 0px;
  }

  .pricingDetailTitle{
    font-size: 40px;
  }

  .pricingOptions{
    position: relative;
  }

  .pricingDetailImage{
    margin-top: 50px;
  }

  .pricingDetailImage img{
    display: none;
  }

  .pricingOptionDescription{
    margin-top: 75px;
  }

  .calculatorValueWrapper{
    width: 100%;
    text-align: center;
    margin-top: 10px;
    margin-left: 0px;
    margin-right: 0px;
  }

  .calculatorValueWrapper2{
    width: 100%;
    text-align: center;
    margin-top: 60px;
    margin-left: 0px;
    margin-right: 0px;
  }

  .calculatorValueWrapper input{
    float: none;
  }

  .calculatorValueWrapper2 input{
    float: none;
  }

  .calculatorOperator{
    width: 100%;
  }

  .pricingOptionFeatureSingle{
    width: 100%;
  }

  .pricingOptionFeatureSingle{
    height: auto;
  }

  .pricingOptionFeatureDescription{
    max-height: none;
  }
}

.remoteStream{
	position: relative;
    float: left;
    margin-top: 40px;
    width: 100%;
    height: 640px;
    text-align: left;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.08) 0px 5px 15px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px;
    border-radius: 4px;
    box-sizing: border-box;
    margin-bottom: 25px;
    padding: 10px;
}

.remoteStreamWrapper{
	width: 100%;
	height: 100%;
  	margin: 0 auto;
  	background-color: #000;
  	-webkit-transform: rotateX(180deg);
    transform: rotateX(180deg);
}

.channelPropertyWrapper{
	position: relative;
	width: 100%;
	height: auto;
	text-align: left;
}

.leftMenuWrapper{
	position: absolute;
	background-color: #2B2B2B;
	width: 75px;
	height: 620px;
	box-sizing: border-box;
	z-index: 10;
}

.freeDrawMainBtn{
	cursor: pointer;
	width: 100%;
	height: 75px;
	float: left;
}

.freeDrawMainBtn img{
	position: relative;
	width: 100%;
	height: auto;
}

.drawMenuWrapper{
	position: absolute;
	top: 0px;
	left: 75px;
	width: 375px;
	background-color: #2B2B2B;
	height: 75px;
	cursor: pointer;
}

.drawThicknessOption{
	position: relative;
	float: left;
	width: 75px;
	height: 75px;
}

.drawThicknessOption img{
	position: relative;
	float: left;
	width: 100%;
	height: 100%;
}

.shapeMenuWrapper{
	position: absolute;
	top: 75px;
	left: 75px;
	width: 450px;
	background-color: #2B2B2B;
	height: 75px;
	cursor: pointer;
}

.paletteBtn{
	cursor: pointer;
	width: 100%;
	height: 75px;
	float: left;
	background-color: #FF0000;
}

.paletteBtn img{
	position: relative;
	width: 100%;
	height: 100%;
}

.colorMenuWrapper{
	position: absolute;
	left: 75px;
	width: 75px;
	height: 620px;
	background-color: #2B2B2B;
}

.colorRed{
	position: relative;
	float: left;
	width: 65px;
	height: 65px;
	margin-left: 5px;
	margin-top: 5px;
	background-color: #FF0000;
	cursor: pointer;
}

.colorGreen{
	position: relative;
	float: left;
	width: 65px;
	height: 65px;
	margin-left: 5px;
	margin-top: 5px;
	background-color: #00C001;
	cursor: pointer;
}

.colorBlue{
	position: relative;
	float: left;
	width: 65px;
	height: 65px;
	margin-left: 5px;
	margin-top: 5px;
	background-color: #001DC0;
	cursor: pointer;
}

.colorMagenta{
	position: relative;
	float: left;
	width: 65px;
	height: 65px;
	margin-left: 5px;
	margin-top: 5px;
	background-color: #C00092;
	cursor: pointer;
}

.colorYellow{
	position: relative;
	float: left;
	width: 65px;
	height: 65px;
	margin-left: 5px;
	margin-top: 5px;
	background-color: #E8F12C;
	cursor: pointer;
}

.colorOrange{
	position: relative;
	float: left;
	width: 65px;
	height: 65px;
	margin-left: 5px;
	margin-top: 5px;
	background-color: #E08312;
	cursor: pointer;
}

.noteMenuWrapper{
	position: absolute;
    /* float: left; */
    z-index: 10;
    left: 85px;
    width: 100%;
    height: 100%;
    padding-right: 95px;
    padding-bottom: 10px
}

.remoteNote{
	position: relative;
	display: inline-block;
	width: 100%;
	min-height: 90%;
	max-height: 90%;
	padding-right: 75px;
	box-sizing: border-box;
	padding: 10px;
}

.noteTextBtnWrapper{
	position: relative;
    float: left;
    width: 100%;
    height: 53px;
    margin-top: -7px;
    text-align: right;
    box-sizing: border-box;
    padding: 8px;
    background-color: #000;
}

.path {
  fill: none;
  stroke-width: 2px;
  stroke: red;
  stroke-linejoin: round;
  stroke-linecap: round;
}

.drawing {
  width: 100%;
  height: 100%;
  -webkit-transform: rotateX(180deg);
  transform: rotateX(180deg);
  position: absolute;
  z-index: 9;
}

.jobLink{
  color: #0313fc !important;
  text-decoration: underline !important;
}
.solutionWrapper {
    position: relative;
    float: left;
    width: 45%;
    height: 350px;
    text-align: left;
    margin-left: 2%;
    margin-right: 2%;
    text-align: center;
    background-color: #fff;
    box-shadow: 0 5px 15px 0 rgba(0,0,0,.08), 0 1px 3px 0 rgba(0,0,0,.12);
    margin-top: 25px;
    color: #4c535d;
    border-radius: 4px;
    box-sizing: border-box;
    padding: 10px;
    cursor: pointer;
}

.solutionFullWrapper {
    position: relative;
    float: left;
    width: 94%;
    height: 350px;
    text-align: left;
    margin-left: 2%;
    margin-right: 2%;
    text-align: center;
    background-color: #fff;
    box-shadow: 0 5px 15px 0 rgba(0,0,0,.08), 0 1px 3px 0 rgba(0,0,0,.12);
    margin-top: 25px;
    color: #4c535d;
    border-radius: 4px;
    box-sizing: border-box;
    padding: 10px;
    cursor: pointer;
}

.solutionFullWrapperImage {
    position: relative;
    max-width: 250px;
    max-height: 200px;
    /* height: auto; */
    width: auto;
    float: left;
    box-sizing: border-box;
    padding: 10px;
}

.solutionFullWrapperImage img{
    position: relative;
    width: 100%;
    height: 100%;
}

.solutionWrapperImage {
    position: relative;
    width: 50%;
    max-width: 250px;
    height: auto;
    float: left;
    box-sizing: border-box;
    padding: 10px;
    max-height: 250px;
}

.solutionWrapperImage img{
    position: relative;
    width: 100%;
    height: 100%;
}

.solutionWrapperTitle {
    position: relative;
    width: 100%;
    height: 50px;
    text-align: left;
    font-size: 18px;
    font-weight: bold;
}

.solutionTextWrapper{
    position: relative;
    float: left;
    width: 50%;
    height: 80%;
}

.solutionDescription{
    position: relative;
    float: left;
    width: 100%;
    height: auto;
    font-size: 12px;
    text-align: left;
}

.solutionPageExplanation {
    position: relative;
    float: left;
    width: 80%;
    margin-left: 10%;
    height: auto;
    margin-top: 10px;
    margin-bottom: 30px;
    font-size: 28px;
    text-align: center;
    font-family: McKinsey Sans,Helvetica Neue,Calibri,Corbel,Helvetica,Roboto,Droid,sans-serif;
}

.downloadWrapper{
    position: relative;
    float: left;
    width: 100%;
    height: 20%;
    box-sizing: border-box;
    text-align: center;
}

.downloadWrapper img{
    position: relative;
    float: left;
    max-width: 50%;
    height: auto;
    max-height: 65px;
    display: block;
}

@media screen and (max-width : 1050px) {
    .solutionFullWrapper {
        position: relative;
        float: left;
        width: 96%;
        height: auto;
        text-align: left;
        margin-left: 2%;
        margin-right: 2%;
        text-align: center;
        background-color: #fff;
        box-shadow: 0 5px 15px 0 rgba(0,0,0,.08), 0 1px 3px 0 rgba(0,0,0,.12);
        margin-top: 25px;
        color: #4c535d;
        border-radius: 4px;
        box-sizing: border-box;
        padding: 10px;
        cursor: pointer;
    }

    .solutionWrapper {
        position: relative;
        float: left;
        width: 96%;
        height: auto;
        text-align: left;
        margin-left: 2%;
        margin-right: 2%;
        text-align: center;
        background-color: #fff;
        box-shadow: 0 5px 15px 0 rgba(0,0,0,.08), 0 1px 3px 0 rgba(0,0,0,.12);
        margin-top: 25px;
        color: #4c535d;
        border-radius: 4px;
        box-sizing: border-box;
        padding: 10px;
        cursor: pointer;
    }

    .solutionFullWrapperImage {
        position: relative;
        width: 100%;
        max-width: none;
        max-height: none;
        height: auto;
        float: left;
        box-sizing: border-box;
        padding: 10px;
    }

    .solutionWrapperImage {
        position: relative;
        width: 100%;
        max-width: none;
        max-height: none;
        height: auto;
        float: left;
        box-sizing: border-box;
        padding: 10px;
    }

    .solutionTextWrapper{
        position: relative;
        float: left;
        width: 100%;
        height: auto;
    }

    .solutionDescription{
        position: relative;
        float: left;
        width: 100%;
        height: auto;
        font-size: 12px;
        text-align: left;
    }
}
.expireDate {
  position: relative;
  float: left;
  width: 16%;
  bottom: 0px;
}

.expireDateUrgent {
  position: relative;
  float: left;
  width: 16%;
  bottom: 0px;
  color: #a83232;
}

.daysRemaining{
  position: relative;
  float: left;
  width: 16%;
  bottom: 0px;
}

.daysRemainingUrgent{
  position: relative;
  float: left;
  width: 16%;
  bottom: 0px;
  color: #a83232;
}

.licenseId{
  position: relative;
  float: left;
  width: 16%;
  bottom: 0px;
}

.licenseStatus{
  position: relative;
  float: left;
  width: 16%;
  bottom: 0px;
}

.licenseUpdate {
  position: relative;
  float: left;
  width: 20%;
  bottom: 0px;
  text-align: right;
}

.licenseRenew {
  position: relative;
  float: left;
  width: 16%;
  bottom: 0px;
  text-align: left;
}

.userWrapper {
  position: relative;
  float: left;
  width: 100%;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 5px 15px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px;
  border-radius: 4px;
  min-height: 60px;
  height: auto;
  margin-top: 10px;
  box-sizing: border-box;
  padding: 15px;
  text-align: left;
  line-height: 50px;
}

.userInfoWrapper{
  position: relative;
  float: left;
  width: 100%;
  height: auto;
}

.userUpdate button{
  margin-left: 10px;
}

.userRolesWrapper{
  position: relative;
  float: left;
  width: 100%;
  height: auto;
}

.userRoleWrapper{
  position: relative;
  float: left;
  width: 100%;
  line-height: 30px;
}

.contactInfoWrapper{
  line-height: 30px !important;
}

.licenseUpdateBtn{
  margin-right: 10px;
}
.licenseFilter{
  position: relative;
  width: 100%;
  height: 20px;
  color: #6d6d6d;
  text-align: left;
  box-sizing: border-box;
  padding: 5px;
  line-height: 20px;
}

.addUserBtn{
  margin-left: 25px;
}

.licenseFilterExpireDate {
  position: relative;
  float: left;
  width: 16%;
  bottom: 0px;
  cursor: pointer;
}

.licenseFilterStatus {
  position: relative;
  float: left;
  width: 16%;
  bottom: 0px;
  cursor: pointer;
}

.licenseFilterId {
  position: relative;
  float: left;
  width: 16%;
  bottom: 0px;
  cursor: pointer;
}

.licenseFilterDaysRemaining {
  position: relative;
  float: left;
  width: 16%;
  bottom: 0px;
  cursor: pointer;
  text-align: left;
}

.licenseFilterUpdate {
  position: relative;
  float: left;
  width: 20%;
  bottom: 0px;
  cursor: pointer;
  text-align: left;
}

.userFilter img {
  position: relative;
  height: 20px;
  line-height: 20px;
  width: auto;
  display: inline-block;
}
.StripeElement {
  margin: 15px auto;
  padding: 10px 12px;
  color: #32325d;
  background-color: whitesmoke;
  border: 1px solid transparent;
  border-radius: 4px;
  width: 100%;
  position: relative;
  float: left;
}

.stripeForm{
  position: relative;
  float: left;
  width: 100%;
  height: auto;
}

.stripeForm label{
  width: 100%;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}

.product {
  width: 100%;
  max-width: 450px;
  margin: auto;
  box-shadow: 0px 15px 30px rgba(0, 0, 0, 0.4);
  border-radius: 10px;
  overflow: hidden;
}

.btn-pay {
  display: block;
  width: 100%;
  border: none;
  background-color: #007bff;
  color: #fff;
  padding: 10px;
  font-size: 18px;
  cursor: pointer;
}

.product-info {
  padding: 0 16px;
}

h3.product-title {
  font-size: 24px;
  margin-bottom: 15px;
}

h4.product-price {
  font-size: 24px;
  margin: 0;
  margin-bottom: 30px;
  color: #777;
  font-weight: 500;
}

.product-description{
  position: relative;
  width: 100%;
  height: auto;
  float: left;
  margin-bottom: 25px;
}

.product-total{
  position: relative;
  width: 100%;
  height: auto;
  float: left;
  margin-bottom: 25px;
  border-top: solid;
  border-top-width: 3px;
  border-color: #ededed;
  text-align: right;
  font-weight: bold;
  font-size: 20px;
}

.subscriptionWrapper{
  text-align: left;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 5px 15px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px;
  margin-bottom: 8px;
  margin-left: 2%;
  margin-right: 2%;
  width: 29%;
  color: rgb(76, 83, 93);
  border-radius: 4px;
  box-sizing: border-box;
  padding: 15px;
  float:left;
  height: auto;
}

.subscriptionWrapperLarge{
  text-align: left;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 5px 15px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px;
  margin-bottom: 8px;
  margin-left: 2%;
  margin-right: 2%;
  width: 29%;
  color: rgb(76, 83, 93);
  border-radius: 4px;
  box-sizing: border-box;
  padding: 15px;
  float:left;
  height: auto;
}

.product-bottom{
  position: relative;
  float: left;
  width: 100%;
  height: auto;
}

.product-bottom-buffer{
  position: relative;
  float: left;
  width: 100%;
  height: 230px;
}

.product-info-wrapper{
  position: relative;
  width: 100%;
  height: auto;
}

.subscriptionWrapper img{
  position: relative;
  float: left;
  width: 100%;
  height: auto;
  margin-bottom: 25px;
}

.subscriptionWrapperLarge img{
  position: relative;
  float: left;
  width: 100%;
  height: auto;
  margin-bottom: 25px;
}

@-webkit-keyframes fade {
  from {
    opacity: 0;
    -webkit-transform: scale3D(0.95, 0.95, 0.95);
            transform: scale3D(0.95, 0.95, 0.95);
  }
  to {
    opacity: 1;
    -webkit-transform: scale3D(1, 1, 1);
            transform: scale3D(1, 1, 1);
  }
}

@keyframes fade {
  from {
    opacity: 0;
    -webkit-transform: scale3D(0.95, 0.95, 0.95);
            transform: scale3D(0.95, 0.95, 0.95);
  }
  to {
    opacity: 1;
    -webkit-transform: scale3D(1, 1, 1);
            transform: scale3D(1, 1, 1);
  }
}

.Form {
  -webkit-animation: fade 200ms ease-out;
          animation: fade 200ms ease-out;
}

.FormGroup {
  margin: 0 15px 20px;
  padding: 0;
  border-style: none;
  background-color: #f7f7f7;
  will-change: opacity, transform;
  /*box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08),
    inset 0 1px 0 #829fff;*/
  border-radius: 4px;
  color: #000000 !important;
}

.FormRow {
  display: flex;
  align-items: center;
  margin-left: 15px;
  /*border-top: 1px solid #819efc;*/
}

.FormRow:first-child {
  border-top: none;
}

.FormRow label{
  margin-bottom: 0px !important;
  width: 20px;
}

.FormRowLabel {
  width: 15%;
  min-width: 70px;
  padding: 11px 0;
  color: #000000;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@-webkit-keyframes void-animation-out {
  0%,
  to {
    opacity: 1;
  }
}

@keyframes void-animation-out {
  0%,
  to {
    opacity: 1;
  }
}
.FormRowInput:-webkit-autofill {
  -webkit-text-fill-color: #fce883;
  /* Hack to hide the default webkit autofill */
  -webkit-transition: background-color 100000000s;
  transition: background-color 100000000s;
  -webkit-animation: 1ms void-animation-out;
          animation: 1ms void-animation-out;
}

.FormRowInput {
  font-size: 16px;
  width: 100%;
  padding: 11px 15px 11px 5px;
  color: #000000;
  background-color: transparent;
  -webkit-animation: 1ms void-animation-out;
          animation: 1ms void-animation-out;
  border-color: #0000004a;
  border-top-color: rgba(0, 0, 0, 0.29);
  border-right-color: rgba(0, 0, 0, 0.29);
  border-bottom-color: rgba(0, 0, 0, 0.29);
  border-left-color: rgba(0, 0, 0, 0.29);
  border-width: 1px;
}

::-webkit-input-placeholder{
  color: #000000;
}

:-ms-input-placeholder{
  color: #000000;
}

::-ms-input-placeholder{
  color: #000000;
}

::placeholder{
  color: #000000;
}

.FormRowInput::-webkit-input-placeholder {
  color: #87bbfd;
}

.FormRowInput:-ms-input-placeholder {
  color: #87bbfd;
}

.FormRowInput::-ms-input-placeholder {
  color: #87bbfd;
}

.FormRowInput::placeholder {
  color: #87bbfd;
}

@media screen and (max-width : 1050px) {
  .subscriptionWrapper{
    margin-left: 0%;
    margin-right: 0%;
    width: 100%;
  }

  .subscriptionWrapperLarge{
    margin-left: 0%;
    margin-right: 0%;
    width: 100%;
  }

  .subscriptionGroup{
    margin-bottom: 0px;
  }
}
.pageDescription a{
  	text-decoration: none;
  	color: #000;
}

.pageDescription a:hover{
 	text-decoration: none;
  	color: #000;
}

.newsArticleWrapper{
	position: relative;
	float: left;
	width: 80%;
	margin-left: 10%;
	box-sizing: border-box;
	padding: 10px;
	text-align: left;
}

.newsImageFull{
	position: relative;
    width: 80%;
    margin-left: 10%;
    height: auto;
}
.deviceHeaderWrapper{
    position: relative;
    width: 100%;
    height: 60px;
    border-bottom-style: solid;
    border-width: 2px;
    border-color: rgb(224, 228, 234) !important;
    font-size: 24px;
    float: left;
    box-sizing: border-box;
    padding-left: 20px;
    padding-right: 20px;
    margin-bottom: 30px;
}

.projectsHeaderText{
    position: relative;
    float: left;
    width: auto;
    height: 60px;
    line-height: 60px;
    margin-right: 50px;
    border-bottom-style: solid;
    border-width: 2px;
    border-color: #C62828;
}

.projectsHeaderSearch{
    position: absolute;
    height: 40px;
    right: 0px;
    top: 5px;
    width: auto;
    font-size: 16px;
}

input.projectsHeaderSearch {
    position: relative;
    width: 250px;
    height: 30px;
    line-height: 30px;
    display: inline-block;
    margin-top: 10px;
    border: none;
    box-sizing: border-box;
    padding-left: 5px;
    padding: 0px 8px;
    border-width: 1px;
    border-style: solid;
    border-color: rgb(154, 168, 187);
    -webkit-border-image: none;
            border-image: none;
    -webkit-border-image: initial;
            border-image: initial;
    border-radius: 4px;
}

.projectsHeaderSearchNew{
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    width: 350px;
    float: left;
    height: 30px;
    margin-top: 15px;
}

.projectsHeaderSearch select{
    position: relative;
    float: left;
    width: auto;
    height: 30px;
    line-height: 30px;
    display: inline-block;
    margin-top: 15px;
    margin-right: 10px;
}

.projectsHeaderSearch label{
    float: left;
    margin-right: 1px;
    margin-top: 17px;
}
.devicePreviewWrapper {
    position: relative;
    width: 14%;
    height: 250px;
    margin-left: 1%;
    margin-right: 1%;
    text-align: center;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.08) 0px 5px 15px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px;
    margin-bottom: 8px;
    color: rgb(76, 83, 93);
    border-radius: 4px;
    box-sizing: border-box;
    padding: 15px;
    float: left;
}

.devicePreviewModel{
	font-size: 11px;
	position: relative;
	width: 100%;
	height: auto;
	display: inline-block;
}

.devicePreviewImage{
	position: relative;
    display: inline-block;
    /* float: left; */
    max-width: 80%;
    width: auto;
    max-height: 220px;
    height: auto;
    /* margin-left: 10%; */
    /* margin-top: 15px; */
    text-align: center;
    vertical-align: middle;
}

.devicePreviewTitle{
	position: relative;
  	width: 100%;
  	height: 50%;
  	font-size: 18px;
  	font-weight: bold;
  	border-bottom-style: solid;
  	border-bottom-color: #d6d6d6;
  	border-bottom-width: 1px;
  	box-sizing: border-box;
  	padding: 15px;
}

.devicePreviewImage img{
	max-width: 100%;
  	width: auto;
  	height: auto;
  	max-height: 220px;
  	display: inline-block;
  	vertical-align: middle;
  	margin-top: 20px;
}

@media screen and (max-width : 900px) {
  .devicePreviewWrapper{
    width: 90%;
    margin-left: 5%;
  }

  .newsPreviewImage{
    width: 100%;
    max-width: 100%;
    margin-left: 0px;
  }
}
.devicesWrapper{
	position: relative;
	float: left;
	width: 100%;
	box-sizing: border-box;
	padding: 5px;
	text-align: center;
}

.devicesExplanation{
	position: relative;
	float: left;
	width: 100%;
	height: auto;
	margin-bottom: 20px;
	text-align: left;
	box-sizing: border-box;
	padding-left: 20px;
	padding-right: 20px;
}

.shapefile{
	position: relative;
	width: 100%;
	height: auto;
	float: left;
}

.featureServiceWrapper{
  position: relative;
  float: left;
  text-align: left;
  width: 100%;
  height: auto;
}

.selectionHeader {
  position: relative;
  width: 100%;
  height: 60px;
  border-bottom-style: solid;
  border-width: 2px;
  border-color: #acacac;
  font-size: 20px;
  line-height: 60px;
  color: #c62828;
  cursor: pointer;
}

.selectFields{
  position: relative;
  width: 100%;
  height: auto;
  float: left;
}

.selectFieldOption{
  position: relative;
  width: 100%;
  height: auto;
  float: left;
}

.selectFieldsHeader{
  position: relative;
  width: 100%;
  height: auto;
  float: left;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: rgb(224, 228, 234);
}

.selectionHeader img {
  position: relative;
  height: 20px;
  margin-top: 20px;
  width: auto;
  float: left;
  cursor: pointer;
}

.layerHeader{
  position: relative;
  float: left;
  width: 100%;
  line-height: 50px;
  font-weight: bold;
}

.layerSelection{
  position: relative;
  float: left;
  width: 100%;
  height: auto;
}

.layerOptionWrapper{
  position: relative;
  float: left;
  width: 100%;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 5px 15px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px;
  border-radius: 4px;
  height: auto;
  cursor: pointer;
  margin-top: 10px;
  box-sizing: border-box;
  padding: 5px;
  text-align: left;
  line-height: 50px;
}

.featureServiceDropdownWrapper{
  display: none;
}

.featureLayerShapefile{
  position: relative;
  margin-left: 10px;
}

.featureServiceName{
  position: relative;
  margin-left: 0px;
  margin-right: 0px;
}

.featureLayerName{
  position: relative;
  margin-left: 0px;
  margin-right: 0px;
}

.featureFieldName{
  position: relative;
  margin-left: 0px;
  margin-right: 0px;
}

.downCarrotHide {
 -webkit-animation: rotate-keyframes 1s;
         animation: rotate-keyframes 1s;
  -webkit-transform:rotate(180deg);
  transform: rotate(180deg);
}

.downCarrotShow{
  -webkit-animation: rotate-keyframes-2 1s;
          animation: rotate-keyframes-2 1s;
}

@-webkit-keyframes rotate-keyframes {
 from {
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
 }

 to {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
 }
}

@keyframes rotate-keyframes {
 from {
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
 }

 to {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
 }
}

@-webkit-keyframes rotate-keyframes-2 {
 from {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
 }

 to {
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
 }
}

@keyframes rotate-keyframes-2 {
 from {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
 }

 to {
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
 }
}


