.pageDescription a{
  text-decoration: none;
  color: #000;
}

.pageDescription a:hover{
  text-decoration: none;
  color: #000;
}

blockquote {
    margin: 0 0 1rem;
    overflow: hidden;
    padding-right: 1.5em;
    padding-left: 1.5em;
    margin-left: 20px;
    margin-right: 0;
    font-style: italic;
    border-left: 5px solid #ccc;
}