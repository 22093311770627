.mainFooterWrapper{
  position: absolute;
  float: left;
  width: 100%;
  height: auto;
  z-index: 4;
  left: 0px;
  text-align: center;
  bottom: 0;
  margin-top: 50px;
  background-color: #2b2b2b;
  color: #ffffff;
  font-family: "Open Sans";
}

.mainFooterWrapper a{
  text-decoration: none;
  color: #fff;
}

.footerSocialWrapper{
  position: relative;
  float: left;
  width: 30%;
  height: auto;
}

.footerSocialImage{
  position: relative;
  width: 100%;
  height: auto;
  box-sizing: border-box;
  text-align: center;
}

.footerSocialImage img{
  position: relative;
  width: auto;
  height: 50px;
  display: inline-block;
}

.footerSocialLogos{
  position: relative;
  float: left;
  width: 100%;
  height: auto;
  box-sizing: border-box;
}

.footerSocialLogos img{
  position: relative;
  padding: 5px;
}

.footerDownloads{
  position: relative;
  float: left;
  width: 100%;
  height: auto;
  box-sizing: border-box;
}

.footerDownloads img{
  position: relative;
  width: auto;
  max-height: 45px;
}

.footerMenu{
  position: relative;
  float: left;
  width: 40%;
  height: inherit;
  text-decoration: none;
  color: #fff;
}

.footerMenu a{
  text-decoration: none;
  color: #fff;
}

.footerEmpty{
  position: relative;
  float: left;
  width: 30%;
  height: auto;
}

.footerEsri{
  position: relative;
  width: 100%;
  height: auto;
  box-sizing: border-box;
  text-align: center;
}

.footerEsri img{
  position: relative;
  width: auto;
  max-width: 100%;
  max-height: 75px;
  display: inline-block;
}

@media screen and (max-width : 1050px) {
  .footerSocialWrapper{
    width: 100%;
  }

  .footerMenu{
    width: 100%;
    margin-top: 10px;
  }

  .footerEmpty{
    width: 100%;
    margin-top: 10px;
  }

  .footerSpace{
    height: 400px !important;
  }
}