.featureServiceWrapper{
  position: relative;
  float: left;
  text-align: left;
  width: 100%;
  height: auto;
}

.selectionHeader {
  position: relative;
  width: 100%;
  height: 60px;
  border-bottom-style: solid;
  border-width: 2px;
  border-color: #acacac;
  font-size: 20px;
  line-height: 60px;
  color: #c62828;
  cursor: pointer;
}

.selectFields{
  position: relative;
  width: 100%;
  height: auto;
  float: left;
}

.selectFieldOption{
  position: relative;
  width: 100%;
  height: auto;
  float: left;
}

.selectFieldsHeader{
  position: relative;
  width: 100%;
  height: auto;
  float: left;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: rgb(224, 228, 234);
}

.selectionHeader img {
  position: relative;
  height: 20px;
  margin-top: 20px;
  width: auto;
  float: left;
  cursor: pointer;
}

.layerHeader{
  position: relative;
  float: left;
  width: 100%;
  line-height: 50px;
  font-weight: bold;
}

.layerSelection{
  position: relative;
  float: left;
  width: 100%;
  height: auto;
}

.layerOptionWrapper{
  position: relative;
  float: left;
  width: 100%;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 5px 15px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px;
  border-radius: 4px;
  height: auto;
  cursor: pointer;
  margin-top: 10px;
  box-sizing: border-box;
  padding: 5px;
  text-align: left;
  line-height: 50px;
}

.featureServiceDropdownWrapper{
  display: none;
}

.featureLayerShapefile{
  position: relative;
  margin-left: 10px;
}

.featureServiceName{
  position: relative;
  margin-left: 0px;
  margin-right: 0px;
}

.featureLayerName{
  position: relative;
  margin-left: 0px;
  margin-right: 0px;
}

.featureFieldName{
  position: relative;
  margin-left: 0px;
  margin-right: 0px;
}

.downCarrotHide {
 animation: rotate-keyframes 1s;
  -webkit-transform:rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}

.downCarrotShow{
  animation: rotate-keyframes-2 1s;
}

@keyframes rotate-keyframes {
 from {
  transform: rotate(0deg);
 }

 to {
  transform: rotate(180deg);
 }
}

@keyframes rotate-keyframes-2 {
 from {
  transform: rotate(180deg);
 }

 to {
  transform: rotate(0deg);
 }
}
