.projectsFilterWrapper{	
	position: relative;
	width: 100%;
	height: 20px;
	margin-top: 30px;
	color: #6d6d6d;
	text-align: left;
	box-sizing: border-box;
	padding: 5px;
	line-height: 20px;
	font-size: 20px;
}

.projectsFilterWrapper img{
    position: relative;
    height: 20px;
    line-height: 20px;
    width: auto;
    display: inline-block;
}

.projectsFilterStatus{
	position: relative;
    float: left;
    width: 10%;
    bottom: 0px;
    cursor: pointer;
}

.projectsFilterId{
    position: relative;
    float: left;
    width: 10%;
    bottom: 0px;
    cursor: pointer;
}

.projectsFilterName{
	position: relative;
    float: left;
    width: 30%;
    bottom: 0px;
    cursor: pointer;
}

.projectsFilterLocation{
	position: relative;
    float: left;
    width: 15%;
    bottom: 0px;
    cursor: pointer;
}

.projectsFilterStart{
	position: relative;
    float: left;
    width: 10%;
    bottom: 0px;
    cursor: pointer;
}

.projectsFilterEnd{
	position: relative;
    float: left;
    width: 10%;
    bottom: 0px;
    cursor: pointer;
}

.projectsFilterResponses{
	position: relative;
    float: left;
    width: 15%;
    bottom: 0px;
    cursor: pointer;
    text-align: right;
}