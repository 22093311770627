.leftRail{
  position: fixed;
  margin-top: 50px;
  width: 50px;
  height: 100%;
  background-color: #2b2b2b;
  z-index: 100;
  top: 0px;
  left: 0px;
}

.fullLeftRail{
  position: fixed;
  margin-top: 50px;
  width: 250px;
  height: 100%;
  background-color: #2b2b2b;
  z-index: 100;
  top: 0px;
  left: 0px;
}

.leftRailMenu {
	position: absolute;
	float: left;
	width: 100%;
	height: 100%;
	margin-top: 20px;
}

.leftRailMenu img{
	position: relative;
    float: left;
    width: 25px;
    height: auto;
    margin-left: 12px;
}

.menuOptionText {
    position: relative;
    width: 200px;
    float: left;
    margin-left: 10px;
    text-align: left;
    color: #ffffff;
}

.menuOptionText a{
	text-decoration: none;
	color: #ffffff;
}

.menuImageWrapper{
	position: relative;
	width: 100%;
	height: 50px;
}

.active{
	border-left: 2px;
	border-left-color: #c62828;
    border-left-style: solid;
    height: 75px;
    position:relative;
    float:left;
}
.childActive{
	border-left: inherit;
	border-left-color: inherit;
    border-left-style: inherit;
    height: inherit;
    position:inherit;
    float: inherit;
}

.childActiveSmall {
    float: left;
    width: 50px;
    height: 25px;
    margin-top: 20px;
    margin-bottom: 20px;
}

.childActiveFull{
	float: left;
	width: 250px;
	height: 25px;
	margin-top: 20px;
	margin-bottom: 20px;
}

.logoutLeftMenuSmall {
    position: fixed;
    bottom: 20px;
    left: 0px;
    width: 50px;
    height: 25px;
    margin-top: 20px;
    cursor: pointer;
}

.logoutLeftMenuFull {
    position: fixed;
    bottom: 20px;
    left: 0px;
    width: 250px;
    height: 25px;
    margin-top: 20px;
    cursor: pointer;
}