.map{
  width: 100% !important;
  height: 600px;
  top: 0px !important;
  position: relative !important;
  overflow: hidden;
  border-style: solid;
  border-width: 1px;
  border-color: #cccccc;
  box-sizing: border-box;
}
.communityMapWrapper{
	width: 100% !important;
    height: auto;
    position: relative;
    box-sizing: border-box;
    margin-bottom: 25px;
}