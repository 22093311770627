.videoPreviewWrapper{
  position: relative;
  float: left;
  width: 31.3%;
  height: 350px;
  text-align: left;
  margin-left: 1%;
  margin-right: 1%;
  text-align: center;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 5px 15px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px;
  margin-bottom: 8px;
  color: rgb(76, 83, 93);
  border-radius: 4px;
  box-sizing: border-box;
  margin-top: 20px;
  cursor: pointer;
}

.videoPreviewTitle{
  position: relative;
  float: left;
  width: 100%;
  height: 20%;
  font-size: 18px;
  font-weight: bold;
  /* border-bottom-style: solid; */
  /* border-bottom-color: #d6d6d6; */
  /* border-bottom-width: 1px; */
  /* min-height: 75px; */
  box-sizing: border-box;
  padding: 15px;
  text-decoration: none;
  color: #000;
  line-height: 45px;
}

.videoPreviewImage{
  position: relative;
  /* display: block; */
  float: left;
  width: 100%;
  /* max-height: 220px; */
  height: 80%;
  /* margin-top: 2%; */
  text-align: center;
  border-radius: 4px;
}

.videoThumb{
  position: relative;
  display: block;
  /* float: left; */
  height: 100%;
  width: 100%;
}

.videoBackground{
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0,0,0,0.5);
  z-index: 50;
}

.expandedWrapper{
  position: fixed;
  width: 60%;
  height: 70%;
  top: 15%;
  left: 20%;
  text-align: center;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 5px 15px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px;
  color: rgb(76, 83, 93);
  border-radius: 4px;
  box-sizing: border-box;
  z-index: 100;
}

.expandedClose{
  position: absolute;
  top: 5px;
  right: 5px;
  width: 25px;
  height: 25px;
}

.expandedClose img{
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 10;
}

.expandedVideoWrapper{
  position: relative;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 30px;
}

.expandedVideoWrapper>iframe{
  position: relative;
  width: 100%;
  height: 100%;
}

@media screen and (max-width : 1050px) {
  .videoPreviewWrapper{
    width: 100%;
    margin-left: 0px;
  }

  .videoPreviewImage{
    width: 100%;
    max-width: 100%;
    margin-left: 0px;
  }
}