/*.contactForm{
  position: relative;
  display: inline-block;
  width: 100%;
  height: auto;
  border-style: solid;
  border-width: 1px;
  border-color: #cbced3;
  margin: auto;
  text-align: left;
  margin-top: 15px;
  box-sizing: border-box;
  padding: 10px;
  color: #000000;
  background-color: #f2f2f2;
  margin-bottom: 10px;
}*/

.contactForm{
  position: relative;
  text-align: left;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 5px 15px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px;
  margin-bottom: 8px;
  width: min-content;
  min-width: 100%;
  color: rgb(76, 83, 93);
  border-radius: 4px;
  box-sizing: border-box;
  padding: 15px;
  height: auto;
}

.contactInput{
  position: relative;
  width: 100%;
  box-sizing: border-box;
  height: 35px;
  padding: 5px;
}

.contactArea{
  position: relative;
  width: 100%;
  max-width: 100%;
  min-width: 100%;
  min-height: 150px;
  height: auto;
  box-sizing: border-box;
  margin-bottom: 15px;
}

.recaptchaWrapper{
  position: relative;
  width: 100%;
  height: auto;
  margin-bottom: 10px;
}

.submitContactBtnWrapper{
  position: relative;
  width: 100%;
  height: auto;
}