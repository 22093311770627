.devicesWrapper{
	position: relative;
	float: left;
	width: 100%;
	box-sizing: border-box;
	padding: 5px;
	text-align: center;
}

.devicesExplanation{
	position: relative;
	float: left;
	width: 100%;
	height: auto;
	margin-bottom: 20px;
	text-align: left;
	box-sizing: border-box;
	padding-left: 20px;
	padding-right: 20px;
}