.map{
  width: 100% !important;
  height: 600px;
  top: 0px !important;
  position: relative !important;
  overflow: hidden;
  border-style: solid;
  border-width: 1px;
  border-color: #cccccc;
  box-sizing: border-box;
}

#toolbarDiv {
    position: absolute;
    top: 15px;
    right: 95px;
    cursor: default;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
 }