.chartWrapper{
  position: relative;
  width: 100%;
  height: 350px;
  float: left;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 5px 15px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px;
  margin-bottom: 8px;
  color: rgb(76, 83, 93);
  border-radius: 4px;
  box-sizing: border-box;
  padding: 15px;
  background-color: white;
  margin-top: 50px;
}

.mainModuleWrapper{
  position: relative;
  float: left;
  width: 100%;
  height: auto;
}