.pricingDetailWrapper{
  position: relative;
  float: left;
  width: 100%;
  height: auto;
  background-color: rgb(255, 255, 255);
  box-sizing: border-box;
  padding: 20px;
  font-family:Lomino, LominoSubset, "open-sans", sans-serif; 
  margin-bottom: 50px;
}

.pricingDetailTitle{
  position: relative;
  float: left;
  width: 100%;
  text-align: left;
  font-size: 60px;
  font-weight: 600;
}

.calculatorOperator{
  position: relative;
  float: left;
  width: 25px;
  text-align: center;
}

.pricingDetailDescription{
  position: relative;
  float: left;
  width: 100%;
  text-align: left;
  font-size: 16px;
  font-weight: 300;
  margin-bottom: 30px;
}

.pricingDetailImage{
  position: relative;
  float: left;
  width: 100%;
  height: auto;
}

.pricingDetailImage img{
  position: relative;
  width: 100%;
  height: auto;
  filter: blur(8px);
  -webkit-filter: blur(8px);
}

.industryWrapperNextImg{
  position: absolute;
  bottom: 0px;
  right: 0px;
  width: 100px;
  height: auto;
}

.pricingOptions{
  position: absolute;
  bottom: 50px;
  left: 0px;
  width: 100%;
  height: 300px;
  z-index: 100;
}

.pricingCalculatorUtilities{
  position: relative;
  float: left;
  width: 100%;
  height: auto;
  margin-top: 20px;
  margin-bottom: 20px;
}

.calculatorInput{
  position: relative;
  float: left;
  width: 100%;
  height: auto;
  margin-top: 20px;
}

.calculatorValueWrapper{
  position: relative;
  float: left;
  width: auto;
  height: auto;
  margin-right: 20px;
  margin-top: 20px;
}

.calculatorValueWrapper2{
  position: relative;
  float: left;
  width: auto;
  height: auto;
  margin-right: 20px;
  margin-top: 20px;
}

.calculatorValueWrapper input{
  position: relative;
  float: left;
  width: 250px;
  height: auto;
  margin-right: 20px;
}

.calculatorValueWrapper2 input{
  position: relative;
  float: left;
  width: 250px;
  height: auto;
  margin-right: 20px;
}

.pricingOption{
  position: relative;
  float: left;
  width: 31.3%;
  height: 300px;
  text-align: left;
  margin-left: 1%;
  margin-right: 1%;
  text-align: center;
  background-color: rgba(250,250,250,1);
  box-shadow: 0 5px 15px 0 rgba(0,0,0,.08), 0 1px 3px 0 rgba(0,0,0,.12);
  margin-bottom: 8px;
  color: #000;
  border-radius: 4px;
  box-sizing: border-box;
  padding: 10px;
}

.pricingOptionTitle{
  position: relative;
  float: left;
  width: 100%;
  height: 50px;
  line-height: 50px;
  font-size: 20px;
  font-weight: bold;
  border-bottom-style: solid;
  border-bottom-color: rgb(224, 228, 234);
  border-bottom-width: 1px;
}

.pricingOptionDescription{
  position: relative;
  float: left;
  width: 100%;
  height: auto;
  margin-top: 30%;
  line-height: 24px;
  font-size: 60px;
  font-weight: bold;
  overflow-wrap: break-word;
}

.industryWrapperNextImg img{
  width: 100%;
  height: auto;
}

.howItWorksVideo{
  width: 100%;
  height: auto;
  position: relative;
  overflow: hidden;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 5px 15px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px;
  margin-bottom: 8px;
  color: rgb(76, 83, 93);
  border-radius: 4px;
  box-sizing: border-box;
  padding: 15px;
  background-color: white;
}

.pricingOptionFeaturesWrapper{
  position: relative;
  float: left;
  width: 100%;
  height: auto;
}

.pricingOptionFeaturesTitle{
  position: relative;
  float: left;
  width: 100%;
  min-height: 75px;
  height: auto;
  font-family: "roboto","Open Sans",sans-serif;
  font-weight: bold;
  font-size: 40px;
  text-align: center;
  margin-top: 35px;
  border-bottom: solid;
  border-width: 2px;
  border-color: #e0e4ea;
}

.pricingOptionFeatureSingle{
  position: relative;
  float: left;
  width: 31.3%;
  height: 250px;
  text-align: left;
  margin-left: 1%;
  margin-right: 1%;
  text-align: left;
  color: #000;
  box-sizing: border-box;
  padding: 10px;
}

.pricingOptionFeatureImage{
  position: relative;
  float: left;
  width: 20%;
  height: auto;
}

.pricingOptionFeatureImage img{
  position: relative;
  float: left;
  width: 100%;
  height: auto;
}

.pricingOptionFeatureDescription{
  position: relative;
  float: left;
  width: 80%;
  height: auto;
  max-height: 200px;
  box-sizing: border-box;
  padding: 5px;
}

.pricingOptionDescriptionTitle{
  position: relative;
  float: left;
  width: 100%;
  height: auto;
  font-size: 20px;
  font-weight: bold;
  font-family: "Open Sans",sans-serif;
  margin-bottom: 20px;
  margin-top: 10px;
}


@media screen and (max-width : 1050px) {
  .pricingOption{
    width: 100%;
    margin-left: 0px;
    margin-right: 0px;
  }

  .pricingDetailTitle{
    font-size: 40px;
  }

  .pricingOptions{
    position: relative;
  }

  .pricingDetailImage{
    margin-top: 50px;
  }

  .pricingDetailImage img{
    display: none;
  }

  .pricingOptionDescription{
    margin-top: 75px;
  }

  .calculatorValueWrapper{
    width: 100%;
    text-align: center;
    margin-top: 10px;
    margin-left: 0px;
    margin-right: 0px;
  }

  .calculatorValueWrapper2{
    width: 100%;
    text-align: center;
    margin-top: 60px;
    margin-left: 0px;
    margin-right: 0px;
  }

  .calculatorValueWrapper input{
    float: none;
  }

  .calculatorValueWrapper2 input{
    float: none;
  }

  .calculatorOperator{
    width: 100%;
  }

  .pricingOptionFeatureSingle{
    width: 100%;
  }

  .pricingOptionFeatureSingle{
    height: auto;
  }

  .pricingOptionFeatureDescription{
    max-height: none;
  }
}