.requestsFilterWrapper{	
	position: relative;
	width: 100%;
	height: 20px;
	margin-top: 30px;
	color: #6d6d6d;
	text-align: left;
	box-sizing: border-box;
	padding: 5px;
	line-height: 20px;
	font-size: 20px;
}

.requestsFilterWrapper img{
    position: relative;
    height: 20px;
    line-height: 20px;
    width: auto;
    display: inline-block;
    top: 3px;
}

.requestsFilterStatus{
	position: relative;
    float: left;
    width: 10%;
    bottom: 0px;
    cursor: pointer;
}

.requestsFilterId{
    position: relative;
    float: left;
    width: 15%;
    bottom: 0px;
    cursor: pointer; 
}

.requestsFilterName{
	position: relative;
    float: left;
    width: 20%;
    bottom: 0px;
    cursor: pointer;
}

.requestsFilterLocation{
	position: relative;
    float: left;
    width: 10%;
    bottom: 0px;
    cursor: pointer;
}

.requestsFilterUtilityType{
    position: relative;
    float: left;
    width: 13%;
    bottom: 0px;
    cursor: pointer;
}

.requestsFilterStart{
	position: relative;
    float: left;
    width: 11%;
    bottom: 0px;
    cursor: pointer;
}

.requestsFilterEnd{
	position: relative;
    float: left;
    width: 11%;
    bottom: 0px;
    cursor: pointer;
}

.requestsFilterResponses{
	position: relative;
    float: left;
    width: 10%;
    bottom: 0px;
    cursor: pointer;
    text-align: right;
}