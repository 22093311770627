.requestViewWrapper{
  position: relative;
  width: 100%;
  height: auto;
  text-align: left;
}

.requestResponseWrapper{
  position: relative;
  float: left;
  width: 100%;
  height: auto;
  margin-bottom: 20px;
}

.requestViewClose {
  position: relative;
  width: auto;
  height: 60px;
  font-size: 20px;
  line-height: 60px;
  float: right;
  cursor: pointer;
  z-index: 10;
  text-decoration: none;
}

.requestViewClose a{
  text-decoration: none !important;
  color: #000 !important;
}

.requestViewTitle {
  position: relative;
  width: 100%;
  height: 60px;
  border-bottom-style: solid;
  border-width: 2px;
  border-color: rgb(224, 228, 234);
  font-size: 20px;
  line-height: 60px;
}

.requestViewTitle img {
  position: relative;
  float: left;
  margin-top: 15px;
  margin-right: 10px;
}

.requestViewDesc {
  position: relative;
  width: 100%;
  height: auto;
  font-size: 18px;
  margin-top: 10px;
}

.approveBtn{
  background-color: #4CAF50;
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  cursor: pointer;
  margin-right: 10px;
  position: relative;
  float: left;
}

.denyBtn{
  background-color: #f44336;
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  cursor: pointer;
  position: relative;
  float: left;
}

.requestResponseText {
  width: 100%;
  position: relative;
  float: left;
  resize: none;
}

.attachBtnWrapper{
  display: inline-block;
  height: 35px;
  width: 35px;
  margin-left: 15px;
  box-shadow:inset 0px 1px 0px 0px #ffffff;
  background:linear-gradient(to bottom, #ffffff 5%, #cccccc 100%);
  background-color:#ffffff;
  border:1px solid #000000;
  display:inline-block;
  cursor:pointer;
  color:#616161;
  font-family:Arial;
  font-size:13px;
  font-weight:bold;
  text-decoration:none;
  float: left;
}

.attachBtn{
  position: relative;
  height: 50%;
  width: 50%;
  margin-left: 25%;
  margin-top: 25%;
}

.attachImgWrapper{
  position: relative;
  width: 100%;
  height: 100%;
}

.requestButtons{
  position: relative;
  float: left;
  width: 100%;
  height: auto;
  margin-bottom: 10px;
}

.uploadOptionsWrapper{
  position: relative;
  left: 35px;
  top: -34px;
  width: 200px;
  height: auto;
  box-shadow:inset 0px 1px 0px 0px #ffffff;
  background-color:#ffffff;
  cursor:pointer;
  color:#616161;
  font-family:Arial;
  font-size:13px;
  font-weight:bold;
  text-decoration:none;
  float: left;
}

.uploadOption{
  position: relative;
  float: left;
  width: 100%;
  height: 25px;
  line-height: 25px;
  text-align: center;
  cursor: pointer;
}

.fileInput{
  width: 100%;
  height: 25px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  cursor: pointer;
}

.fileInput + label{
  width: 100%;
  height: 25px;
  text-align: center;
  cursor: pointer;
  line-height: 25px;
  margin-bottom: 0px !important;
}

.uploadedFilesWrapper{
  position: relative;
  width: 100%;
  min-height: 75px;
  height: auto;
  background-color: #fff;
  float: left;
  margin-bottom: 10px;
  box-sizing: border-box;
  padding: 5px;
}

.singleFileWrapper{
  position: relative;
  float: left;
  min-width: 300px;
  width: auto;
  height: 80px;
  box-sizing: border-box;
  padding-left: 5px;
  line-height: 75px;
  background-color: #f5f5f5;
  border: 1px solid #c4c4c4;
  margin-left: 5px;
  margin-top: 5px;
  margin-bottom: 5px;
}

.fileDelete{
  position: absolute;
  top: 5px;
  right: 5px;
  width: 25px;
  height: 25px;
  background-color: #fff;
  border: 1px solid #000;
  cursor: pointer;
}

.fileDelete img{
  position: relative;
  float: left;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.fileName{
  position: relative;
  width: 100%;
  height: 40px;
  box-sizing: border-box;
  line-height: 40px;
}