.bimProjectViewWrapper{
  position: relative;
  float: left;
  width: 100%;
  height: auto;
  box-sizing: border-box;
  padding: 10px;
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 5px 15px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px;
  color: rgb(76, 83, 93);
  border-radius: 4px;
  margin-top: 20px;
}

.bimProjectThumb{
  position: relative;
  float: left;
  height: auto;
  width: 25%;
}

.bimProjectThumb img{
  position: relative;
  float: left;
  width: 100%;
  height: 100%;
}

.bimProjectInfo{
  position: relative;
  float: left;
  width: 73%;
  height: auto;
  margin-left: 2%;
}

.bimProjectInfo input{
  position: relative;
  float: left;
  width: 100%;
}

.bimProjectInfo textarea{
  min-height: 150px;
  font-size: 15px;
  line-height: 30px;
  display: inline-block;
  /* max-width: 90%; */
  min-width: 100%;
  font-family: Arial;
}

.modelPreviewWrapper{
  position: relative;
  float: left;
  width: 100%;
  height: auto;
  margin-top: 15px;
  margin-bottom: 15px;
}

.bimPreviewButtons{
  position: relative;
  float: right;
  width: 100%;
  height: auto;
  margin-top: 10px;
  text-align: right;
}

.bimPreviewButtons button{
  margin-right: 10px;
}