.partnersWrapper{
	position: relative;
    float: left;
    width: 80%;
    height: auto;
    margin-left: 10%;
    font-size: 15px;
    text-align: left;
    margin-top: 15px;
    margin-bottom: 30px;
}

.partnersWrapper img{
	position: relative;
	float: left;
	width: auto;
	height: 60px;
	margin-left: 10px;
	margin-right: 10px;
}

.container{
    display: flex;
    flex-wrap: wrap;
}
.card {
    flex: 1 0 auto;
    box-sizing: border-box;
    margin: 1rem .25em;
    width: 190px;
    height: 330px;
    box-shadow: rgba(0, 0, 0, 0.08) 0px 5px 15px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px;
    border-radius: 4px;
    color: rgb(76, 83, 93);
    /*
position: relative;
  float: left;
  width: 300px;
  height: 280px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 5px 15px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px;
  margin-top: 15px;
  margin-bottom: 8px;
  color: rgb(76, 83, 93);
  border-radius: 4px;
  box-sizing: border-box;
  padding: 0px;*/
}

.card-body {
    padding: 10px;
}

.card-title{
    position: relative;
    float: left;
    width: 100%;
    font-size: 18px;
    font-weight: bold;
    padding-bottom: 10px;
    color: rgb(76, 83, 93);
    border-bottom-style: solid;
    border-bottom-color: #d6d6d6;
    border-bottom-width: 1px;
}

.card-text{
    position: relative;
    float: left;
    width: 100%;
    height: auto;
    font-size: 16px;
    min-height: 75px;
    box-sizing: border-box;
    padding: 0px;
    color: rgb(76, 83, 93);
}

.card-img{
    max-width: 100%;
    width: auto;
    height: 80px;
    margin: 5px;
   
}