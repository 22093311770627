.expireDate {
  position: relative;
  float: left;
  width: 16%;
  bottom: 0px;
}

.expireDateUrgent {
  position: relative;
  float: left;
  width: 16%;
  bottom: 0px;
  color: #a83232;
}

.daysRemaining{
  position: relative;
  float: left;
  width: 16%;
  bottom: 0px;
}

.daysRemainingUrgent{
  position: relative;
  float: left;
  width: 16%;
  bottom: 0px;
  color: #a83232;
}

.licenseId{
  position: relative;
  float: left;
  width: 16%;
  bottom: 0px;
}

.licenseStatus{
  position: relative;
  float: left;
  width: 16%;
  bottom: 0px;
}

.licenseUpdate {
  position: relative;
  float: left;
  width: 20%;
  bottom: 0px;
  text-align: right;
}

.licenseRenew {
  position: relative;
  float: left;
  width: 16%;
  bottom: 0px;
  text-align: left;
}

.userWrapper {
  position: relative;
  float: left;
  width: 100%;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 5px 15px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px;
  border-radius: 4px;
  min-height: 60px;
  height: auto;
  margin-top: 10px;
  box-sizing: border-box;
  padding: 15px;
  text-align: left;
  line-height: 50px;
}

.userInfoWrapper{
  position: relative;
  float: left;
  width: 100%;
  height: auto;
}

.userUpdate button{
  margin-left: 10px;
}

.userRolesWrapper{
  position: relative;
  float: left;
  width: 100%;
  height: auto;
}

.userRoleWrapper{
  position: relative;
  float: left;
  width: 100%;
  line-height: 30px;
}

.contactInfoWrapper{
  line-height: 30px !important;
}

.licenseUpdateBtn{
  margin-right: 10px;
}