.projectWrapper{
  position: relative;
  float: left;
  width: 100%;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 5px 15px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px;
  border-radius: 4px;
  height: 60px;
  cursor: pointer;
  margin-top: 10px;
  box-sizing: border-box;
  padding: 5px;
  text-align: left;
  line-height: 50px;
  text-decoration: none;
  color: #000;
  font-size: 18px;
}

.projectStatus {
  position: relative;
  float: left;
  width: 10%;
  bottom: 0px;
}

.projectStatus img {
  position: relative;
  float: left;
  width: 28px;
  height: 31px;
  margin-top: 10px;
  margin-left: 15px;
}

.ticketId{
  position: relative;
  float: left;
  width: 10%;
  height: 60px;
  overflow: hidden;
  cursor: pointer;
  box-sizing: border-box;
  padding-right: 10px;
}

.projectName {
  position: relative;
  float: left;
  width: 30%;
  height: 60px;
  overflow: hidden;
  cursor: pointer;
  box-sizing: border-box;
  padding-right: 10px;
}

.projectLocation {
  position: relative;
  float: left;
  width: 15%;
  cursor: pointer;
}

.projectStartDate {
  position: relative;
  float: left;
  width: 10%;
  cursor: pointer;
}

.projectEndDate {
  position: relative;
  float: left;
  width: 10%;
  cursor: pointer;
}

.projectResponses {
  position: relative;
  float: left;
  width: 15%;
  text-align: right;
  cursor: pointer;
}

a{
  text-decoration: none;
}