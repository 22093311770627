.requestsHeaderWrapper{
    position: relative;
    width: 100%;
    height: 60px;
    border-bottom-style: solid;
    border-width: 2px;
    border-color: rgb(224, 228, 234) !important;
    font-size: 24px;
}

.requestsHeaderText{
    position: relative;
    float: left;
    width: auto;
    height: 60px;
    line-height: 60px;
    margin-right: 50px;
    border-bottom-style: solid;
    border-width: 2px;
    border-color: #C62828;
    cursor: pointer;
}

.requestsHeaderSearch{
    position: absolute;
    height: 40px;
    right: 0px;
    top: 5px;
    width: auto;
    font-size: 16px;
}

input.requestsHeaderSearch {
    position: relative;
    width: 250px;
    height: 30px;
    line-height: 30px;
    display: inline-block;
    margin-top: 10px;
    border: none;
    box-sizing: border-box;
    padding-left: 5px;
    padding: 0px 8px;
    border-width: 1px;
    border-style: solid;
    border-color: rgb(154, 168, 187);
    border-image: initial;
    border-radius: 4px;
}

.requestsHeaderSearch select{
    position: relative;
    float: left;
    width: auto;
    height: 30px;
    line-height: 30px;
    display: inline-block;
    margin-top: 15px;
    margin-right: 10px;
    padding: 0px;
}

.requestsHeaderSearch label{
    float: left;
    margin-right: 1px;
    margin-top: 17px;
}