.adminPageTitle {
    position: relative;
    float: left;
    width: 100%;
    color: #c62828;
    font-size: 35px;
    text-align: left;
    margin-top: 15px;
    border-bottom-style: solid;
    border-color: #acacac;
    border-width: 2px;
}

.adminPageDescription {
    position: relative;
    float: left;
    width: 100%;
    height: auto;
    /* margin-left: 10%; */
    font-size: 15px;
    text-align: left;
    margin-top: 15px;
    margin-bottom: 30px;
}