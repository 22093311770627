a{
  text-decoration: none;
}

.noProjects{
  position: relative;
  float: left;
  width: 80%;
  margin-left: 10%;
  height: auto;
  color: #000;
  box-sizing: border-box;
  padding: 15px;
  margin-top: 20px;
  box-shadow:  0 0 10px  rgba(0,0,0,0.6);
  -moz-box-shadow: 0 0 10px  rgba(0,0,0,0.6);
  -webkit-box-shadow: 0 0 10px  rgba(0,0,0,0.6);
  -o-box-shadow: 0 0 10px  rgba(0,0,0,0.6);
  border-radius: 15px;
  background-color: #fff3cd;
  border-color: #ffeeba;
}

.bimProjectWrapper{
  position: relative;
  float: left;
  width: 100%;
  height: auto;
  margin-top: 20px;
}