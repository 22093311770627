.remoteStream{
	position: relative;
    float: left;
    margin-top: 40px;
    width: 100%;
    height: 640px;
    text-align: left;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.08) 0px 5px 15px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px;
    border-radius: 4px;
    box-sizing: border-box;
    margin-bottom: 25px;
    padding: 10px;
}

.remoteStreamWrapper{
	width: 100%;
	height: 100%;
  	margin: 0 auto;
  	background-color: #000;
  	-webkit-transform: rotateX(180deg);
    transform: rotateX(180deg);
}

.channelPropertyWrapper{
	position: relative;
	width: 100%;
	height: auto;
	text-align: left;
}

.leftMenuWrapper{
	position: absolute;
	background-color: #2B2B2B;
	width: 75px;
	height: 620px;
	box-sizing: border-box;
	z-index: 10;
}

.freeDrawMainBtn{
	cursor: pointer;
	width: 100%;
	height: 75px;
	float: left;
}

.freeDrawMainBtn img{
	position: relative;
	width: 100%;
	height: auto;
}

.drawMenuWrapper{
	position: absolute;
	top: 0px;
	left: 75px;
	width: 375px;
	background-color: #2B2B2B;
	height: 75px;
	cursor: pointer;
}

.drawThicknessOption{
	position: relative;
	float: left;
	width: 75px;
	height: 75px;
}

.drawThicknessOption img{
	position: relative;
	float: left;
	width: 100%;
	height: 100%;
}

.shapeMenuWrapper{
	position: absolute;
	top: 75px;
	left: 75px;
	width: 450px;
	background-color: #2B2B2B;
	height: 75px;
	cursor: pointer;
}

.paletteBtn{
	cursor: pointer;
	width: 100%;
	height: 75px;
	float: left;
	background-color: #FF0000;
}

.paletteBtn img{
	position: relative;
	width: 100%;
	height: 100%;
}

.colorMenuWrapper{
	position: absolute;
	left: 75px;
	width: 75px;
	height: 620px;
	background-color: #2B2B2B;
}

.colorRed{
	position: relative;
	float: left;
	width: 65px;
	height: 65px;
	margin-left: 5px;
	margin-top: 5px;
	background-color: #FF0000;
	cursor: pointer;
}

.colorGreen{
	position: relative;
	float: left;
	width: 65px;
	height: 65px;
	margin-left: 5px;
	margin-top: 5px;
	background-color: #00C001;
	cursor: pointer;
}

.colorBlue{
	position: relative;
	float: left;
	width: 65px;
	height: 65px;
	margin-left: 5px;
	margin-top: 5px;
	background-color: #001DC0;
	cursor: pointer;
}

.colorMagenta{
	position: relative;
	float: left;
	width: 65px;
	height: 65px;
	margin-left: 5px;
	margin-top: 5px;
	background-color: #C00092;
	cursor: pointer;
}

.colorYellow{
	position: relative;
	float: left;
	width: 65px;
	height: 65px;
	margin-left: 5px;
	margin-top: 5px;
	background-color: #E8F12C;
	cursor: pointer;
}

.colorOrange{
	position: relative;
	float: left;
	width: 65px;
	height: 65px;
	margin-left: 5px;
	margin-top: 5px;
	background-color: #E08312;
	cursor: pointer;
}

.noteMenuWrapper{
	position: absolute;
    /* float: left; */
    z-index: 10;
    left: 85px;
    width: 100%;
    height: 100%;
    padding-right: 95px;
    padding-bottom: 10px
}

.remoteNote{
	position: relative;
	display: inline-block;
	width: 100%;
	min-height: 90%;
	max-height: 90%;
	padding-right: 75px;
	box-sizing: border-box;
	padding: 10px;
}

.noteTextBtnWrapper{
	position: relative;
    float: left;
    width: 100%;
    height: 53px;
    margin-top: -7px;
    text-align: right;
    box-sizing: border-box;
    padding: 8px;
    background-color: #000;
}

.path {
  fill: none;
  stroke-width: 2px;
  stroke: red;
  stroke-linejoin: round;
  stroke-linecap: round;
}

.drawing {
  width: 100%;
  height: 100%;
  -webkit-transform: rotateX(180deg);
  transform: rotateX(180deg);
  position: absolute;
  z-index: 9;
}