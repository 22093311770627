.subscriptionGroupTitle{
    border-bottom: solid;
    border-width: 2px;
    border-color: #e0e4ea;
    margin-bottom: 10px;
}

.subscriptionOptions{
  position: relative;
  width: 100%;
  height: auto;
  display: inline-block;
}

.subscriptionGroup{
  position: relative;
  width: 100%;
  height: auto;
  float: left;
  margin-bottom: 30px;
}

.createAccount button{
  background-color: #c62828;
  border: none;
  color: #ffffff;
  height: 50px;
  position: relative;
  max-width: 400px;
  width: 100%;
  margin-bottom: 20px;
  font-size: 20px;
  cursor: pointer;
}

.createAccount{
  text-align: left;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 5px 15px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px;
  margin-bottom: 8px;
  width: 100%;
  min-width: 100%;
  color: rgb(76, 83, 93);
  border-radius: 4px;
  box-sizing: border-box;
  padding: 15px;
  float: left;
}

.accountTypeInfo img{
  width: 20px;
  height: auto;
}

.accountTypeInfo{
  width: 25px;
  height: auto;
  position: relative;
  display: inline-block;
}

.infoPopup{
  position: absolute;
  left: 20px;
  top: 0px;
  display: none;
  width: 500px;
  height: auto;
  background-color: #2b2b2b;
  color: #fff;
  box-sizing: border-box;
  padding: 10px;
}

.accountTypeInfo:hover .infoPopup{
  display: block;
}

.stripeLogo{
  width: 150px;
  height: auto;
}

.mailingWrapper{
  position: fixed;
  text-align: left;
  z-index: 1001;
  left: 50%;
  top: 50%;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 5px 15px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px;
  color: rgb(76, 83, 93);
  border-radius: 4px;
  box-sizing: border-box;
  padding: 15px;
  transform: translate(-50%, -50%);
  /*min-height: 60%;*/
  height: 80%;
  width: 80%;
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  border-radius: 25px;
  overflow: hidden;
  overflow-y: scroll;
}

.mailingBackground{
  position: fixed;
  z-index: 1000;
  height: 100%;
  width: 100%;
  background: rgba( 0, 0, 0, .6 )
}

.mailingClose{
  position: absolute;
  right: 15px;
  top: 15px;
  width: 20px;
  height: 20px;
  cursor: pointer;
  z-index: 1002;
}

.mailingHeader{
  position: relative;
  float: left;
  width: 100%;
  height: auto;
  font-size: 28px;
  font-weight: bold;
}

.mailingClose img{
  position: relative;
  width: 100%;
  height: 100%;
}

.mailingInfo{
  position: relative;
  float: left;
  width: 100%;
  height: auto;
  margin-top: 30px;
  overflow: hidden;
}

.mailingApprove{
  position: relative;
  float: left;
  width: 100%;
  height: auto;
}

.mailingApprove button{
  background-color: #c62828;
  border: none;
  color: #ffffff;
  min-height: 50px;
  height: auto;
  position: relative;
  max-width: 600px;
  width: 100%;
  margin-bottom: 20px;
  font-size: 20px;
  cursor: pointer;
}

@media screen and (max-width : 1050px) {
  .mailingWrapper{
    width: 95%;
    height: 95%;
  }
}
/*.recaptchaContainer{
  position: relative;
  transform: scale(calc(0.8));
  transform-origin: 0 0;
}*/