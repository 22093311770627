.moduleMenu{
	position: relative;
	float: left;
	width: 97%;
	height: auto;
	min-height: 100px;
	background-color: #fff;
	margin-top: 30px;
	line-height: 100px
}

.moduleMenu button{
	display: relative;
	float: left;
	margin-top: 30px;
	margin-right: 10px;
}