.accountUpdate {
  position: relative;
  float: right;
  width: auto;
  bottom: 0px;
  cursor: pointer;
  text-align: right;
}

.accountUpdate button{
  margin-left: 10px;
}